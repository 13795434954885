import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Box, Icon, Text } from "native-base";
import { useWindowDimensions } from "react-native";
import { Outlet, useLocation } from "react-router";
import { Link } from "~/features/navigation/router";
import React from "react";
import { ChangelogsModal } from "~/features/changelogs/modal";

export type Tab = {
  label: string;
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  prefix: string;
  path: string;
};
const tabs: Tab[] = [
  {
    label: "Home",
    icon: "home",
    prefix: "/locate",
    path: "/locate/all",
  },
  // {
  //   label: "Messages",
  //   icon: "bell",
  //   prefix: "/messages",
  //   path: "/messages",
  // },
  {
    label: "Settings",
    icon: "cog",
    prefix: "/settings",
    path: "/settings",
  },
];

export function TabbedScreen() {
  const location = useLocation();
  const windowSize = useWindowDimensions();
  const isDesktop = windowSize.width >= 800;

  if (isDesktop) {
    return (
      <Box
        flexDirection="row"
        bg="primary.200"
        _dark={{ bg: "dark.100" }}
        style={{ flex: 1 }}
        flex={1}
      >
        <Box bg="primary.800" width="80px">
          <Box flex={1} flexDirection="column" height="full" alignSelf="center">
            {tabs.map((i) => (
              <Link
                px="2"
                py="4"
                to={i.path}
                key={i.path}
                alignItems="center"
                justifyContent="center"
                alignSelf="stretch"
              >
                <Icon
                  color={location.pathname.startsWith(i.prefix) ? "white" : "primary.300"}
                  as={MaterialCommunityIcons}
                  name={i.icon}
                  size="md"
                />
                <Text color={location.pathname.startsWith(i.prefix) ? "white" : "primary.300"}>
                  {i.label}
                </Text>
              </Link>
            ))}
          </Box>
        </Box>
        <Outlet />
        <ChangelogsModal />
      </Box>
    );
  }

  return (
    <Box
      flexDirection="column"
      bg="primary.200"
      _dark={{ bg: "dark.100" }}
      style={{ flex: 1 }}
      flex={1}
      safeAreaBottom
    >
      <Outlet />
      <Box height="56px">
        <Box flex={1} flexDirection="row" width="full" maxWidth="400px" alignSelf="center">
          {tabs.map((i) => (
            <Link
              to={i.path}
              key={i.path}
              alignItems="center"
              justifyContent="center"
              alignSelf="stretch"
              flex={1}
            >
              <Icon
                color={location.pathname.startsWith(i.prefix) ? "primary.800" : "gray.600"}
                _dark={{
                  color: location.pathname.startsWith(i.prefix) ? "primary.500" : "gray.500",
                }}
                as={MaterialCommunityIcons}
                name={i.icon}
                size="md"
              />
              <Text
                color={location.pathname.startsWith(i.prefix) ? "primary.800" : "gray.600"}
                _dark={{
                  color: location.pathname.startsWith(i.prefix) ? "primary.500" : "gray.500",
                }}
              >
                {i.label}
              </Text>
            </Link>
          ))}
        </Box>
      </Box>
      <ChangelogsModal />
    </Box>
  );
}
