import {
  Alert,
  CloseIcon,
  HStack,
  IconButton,
  Text,
  useToast as NB_useToast,
  VStack,
} from "native-base";
import React from "react";

export interface ToastAlertProps {
  id: string;
  status: "success" | "danger" | "warning" | "info";
  title: string;
  description?: string;
  isClosable?: boolean;
  duration?: number;
}

function ToastAlert({
  id,
  status,
  // variant,
  title,
  description,
  isClosable,
  ...rest
}: ToastAlertProps) {
  const { close } = NB_useToast();
  return (
    <Alert
      maxWidth="100%"
      alignSelf="center"
      flexDirection="row"
      status={status ?? "info"}
      variant="solid"
      {...rest}
    >
      <VStack space={1} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text fontSize="md" fontWeight="medium" flexShrink={1} color={"lightText"}>
              {title}
            </Text>
          </HStack>
          {isClosable ? (
            <IconButton
              variant="unstyled"
              icon={<CloseIcon size="3" />}
              _icon={{
                color: "lightText",
              }}
              onPress={() => close(id)}
            />
          ) : null}
        </HStack>
        {!!description && (
          <Text px="6" color="lightText">
            {description}
          </Text>
        )}
      </VStack>
    </Alert>
  );
}

export function useToast() {
  const { show } = NB_useToast();

  return {
    show: (item: Omit<ToastAlertProps, "id">) => {
      show({
        duration: item.duration ?? 2000,
        render: ({ id }) => {
          return <ToastAlert id={id} {...item} />;
        },
      });
    },
  };
}
