import BottomSheet from "@gorhom/bottom-sheet";
import { Box, useColorMode, useToken } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { SharedValue } from "react-native-reanimated";
import { BlurredBottomSheetBackground } from "~/components/Blurred/BlurredBottomSheetBackground";
import { useAtomValue } from "jotai";
import { disableBottomSheetAtom } from "~/features/settings/debugging/screen";

export interface BlurredBottomSheetProps {
  children: React.ReactNode;
  snapPoints: (string | number)[];
  animatedIndex: SharedValue<number>;
  onChangeIndex: (index: number) => void;
}

export function BlurredBottomSheet(props: BlurredBottomSheetProps) {
  const { colorMode } = useColorMode();
  const handleColor = useToken("colors", colorMode === "dark" ? "gray.300" : "gray.800");
  const disableBottomSheet = useAtomValue(disableBottomSheetAtom);
  if (Platform.OS === "web" || disableBottomSheet) {
    return (
      <Box
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: props.snapPoints[props.animatedIndex.value],
        }}
      >
        <BlurredBottomSheetBackground
          animatedIndex={props.animatedIndex}
          style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
        />
        {props.children}
      </Box>
    );
  }
  return (
    <BottomSheet
      backgroundComponent={BlurredBottomSheetBackground}
      handleIndicatorStyle={{
        backgroundColor: handleColor,
      }}
      animatedIndex={props.animatedIndex}
      onChange={props.onChangeIndex}
      animateOnMount={false}
      snapPoints={props.snapPoints}
    >
      {props.children}
    </BottomSheet>
  );
}
