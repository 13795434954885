import { FirstDayOfWeek, useMonth } from "@datepicker-react/hooks";
import { VStack, HStack, Text, Box, IconButton, Icon, Heading } from "native-base";
import { Day } from "./Day";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export interface MonthProps {
  month: number;
  year: number;
  firstDayOfWeek: FirstDayOfWeek;
  prev?: () => void;
  next?: () => void;
}

export function Month({ year, month, firstDayOfWeek, prev, next }: MonthProps) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });
  return (
    <VStack flex={1}>
      <HStack alignItems="center">
        {!!prev && (
          <IconButton
            icon={<Icon as={MaterialCommunityIcons} name="chevron-left" />}
            onPress={prev}
          />
        )}
        <Heading textAlign="center" flex="1" fontSize="lg">
          {monthLabel}
        </Heading>
        {!!next && (
          <IconButton
            icon={<Icon as={MaterialCommunityIcons} name="chevron-right" />}
            onPress={next}
          />
        )}
      </HStack>
      <HStack>
        {weekdayLabels.map((label) => (
          <Box
            justifyContent="center"
            alignItems="center"
            width={`${100 / 7}%`}
            flexGrow={1}
            flexShrink={0}
            key={label}
          >
            <Text fontWeight="medium" textAlign="center">
              {label}
            </Text>
          </Box>
        ))}
      </HStack>
      <HStack flexWrap="wrap">
        {days.map((day, n) =>
          typeof day === "number" ? (
            <Box
              key={n}
              style={{
                width: `${100 / 7}%`,
                flexGrow: 1,
                flexShrink: 0,
              }}
            />
          ) : (
            <Day key={day.dayLabel} dayLabel={day.dayLabel} date={day.date} />
          )
        )}
        {new Array(6).fill(0).map((_, n) => (
          <Box
            key={n}
            style={{
              width: `${100 / 7}%`,
              flexGrow: 1,
              flexShrink: 0,
            }}
          />
        ))}
      </HStack>
    </VStack>
  );
}
