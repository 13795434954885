// Based on https://dev.to/karlmarxlopez/a-simple-reusable-keyboard-avoiding-animation-hook-with-typescript-reanimated-v2-1npj
import { useDerivedValue, useSharedValue } from "react-native-reanimated";
import { Keyboard, KeyboardEventListener, ScreenRect } from "react-native";
import { useCallback, useEffect } from "react";

export function useKeyboardHeight() {
  const keyboardVisible = useSharedValue(false);
  const keyboardEndCoordinates = useSharedValue<ScreenRect | null>(null);
  const keyboardHeight = useDerivedValue(() => {
    if (keyboardVisible.value && keyboardEndCoordinates.value) {
      return keyboardEndCoordinates.value.height;
    }
    return 0;
  }, [keyboardEndCoordinates]);

  const handleKeyboardWillChangeFrame = useCallback<KeyboardEventListener>(
    ({ endCoordinates }) => {
      keyboardVisible.value = true;
      keyboardEndCoordinates.value = endCoordinates;
    },
    [keyboardEndCoordinates, keyboardVisible]
  );

  const handleKeyboardWillHide = useCallback<KeyboardEventListener>(
    ({ endCoordinates }) => {
      keyboardVisible.value = false;
      keyboardEndCoordinates.value = endCoordinates;
    },
    [keyboardEndCoordinates, keyboardVisible]
  );

  useEffect(() => {
    const emitter = Keyboard.addListener("keyboardWillChangeFrame", handleKeyboardWillChangeFrame);

    return () => emitter.remove();
  }, [handleKeyboardWillChangeFrame]);

  useEffect(() => {
    const emitter = Keyboard.addListener("keyboardWillHide", handleKeyboardWillHide);

    return () => emitter.remove();
  }, [handleKeyboardWillHide]);

  return { keyboardVisible, keyboardEndCoordinates, keyboardHeight };
}
