import { useToken } from "native-base";

const speedBrackets: [minSpeed: number, color: string][] = [
  [0, "indigo.400"],
  [30, "cyan.400"],
  [40, "green.400"],
  [60, "amber.400"],
  [70, "red.400"],
];

export interface SpeedBracket {
  minSpeed: number;
  speedString: string;
  color: string;
  label: string;
}

export const speedBracketsString = speedBrackets.map((bracket) => bracket[0].toString()).join(",");

export function useSpeedBrackets() {
  const tokens = useToken(
    "colors",
    speedBrackets.map((i) => i[1])
  );

  return speedBrackets.map((bracket, index) => {
    return {
      minSpeed: bracket[0],
      speedString: bracket[0].toString(),
      color: tokens[index] as string,
      label: speedBrackets[index + 1]
        ? `${bracket[0]}-${speedBrackets[index + 1][0]}`
        : `${bracket[0]}+`,
    };
  });
}

export function getSpeedBracket(
  speedBrackets: SpeedBracket[],
  speed: number | string
): SpeedBracket;
export function getSpeedBracket(
  speedBrackets: SpeedBracket[],
  speed: number | string | null
): SpeedBracket | null;
export function getSpeedBracket(speedBrackets: SpeedBracket[], speed: number | string | null) {
  if (speed === null) return null;
  if (typeof speed === "string") {
    return speedBrackets.find((bracket) => speed === bracket.speedString) ?? speedBrackets[0];
  }
  return (
    speedBrackets
      .slice()
      .reverse()
      .find((bracket) => speed >= bracket.minSpeed) ?? speedBrackets[0]
  );
}

export function useSpeedBracket(speed: number | string): SpeedBracket;
export function useSpeedBracket(speed: number | string | null): SpeedBracket | null;
export function useSpeedBracket(speed: number | string | null) {
  const speedBrackets = useSpeedBrackets();
  return getSpeedBracket(speedBrackets, speed);
}
