import { IInputProps, Input } from "native-base";
import { useCallback } from "react";
import { ANIMATION_SOURCE, useBottomSheetInternal } from "@gorhom/bottom-sheet";

export function BottomSheetInput({ onFocus, ...rest }: IInputProps) {
  const bottomSheet = useBottomSheetInternal(true);

  const handleOnFocus = useCallback(
    (args) => {
      bottomSheet?.animateToPosition(
        bottomSheet?.animatedSnapPoints.value[1],
        ANIMATION_SOURCE.USER
      );
      if (onFocus) {
        onFocus(args);
      }
    },
    [onFocus, bottomSheet]
  );

  return <Input onFocus={handleOnFocus} {...rest} />;
}
