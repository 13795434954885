import { Appearance, ColorSchemeName } from "react-native";
import { useCallback, useEffect, useRef, useState } from "react";

// Source: https://github.com/expo/expo/issues/10815#issuecomment-995830659
export function useSystemColorScheme(delay = 500): NonNullable<ColorSchemeName> {
  const [colorScheme, setColorScheme] = useState(Appearance.getColorScheme());
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const resetCurrentTimeout = useCallback(() => {
    if (timeout?.current) {
      clearTimeout(timeout.current);
    }
  }, [timeout]);

  const onColorSchemeChange = useCallback(
    (preferences: Appearance.AppearancePreferences) => {
      resetCurrentTimeout();

      timeout.current = setTimeout(() => {
        setColorScheme(preferences.colorScheme);
      }, delay);
    },
    [delay, resetCurrentTimeout]
  );

  useEffect(() => {
    const AppearanceChange = Appearance.addChangeListener(onColorSchemeChange);
    return () => {
      resetCurrentTimeout();
      if (AppearanceChange) AppearanceChange.remove();
    };
  }, [onColorSchemeChange, resetCurrentTimeout]);

  return colorScheme as NonNullable<ColorSchemeName>;
}
