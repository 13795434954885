import { createEndpoint } from "~/common/api/endpoint";
import { useAPIQuery } from "~/common/api/useAPIQuery";
import { useMemo } from "react";

// TODO: Group V2

export interface GroupData {
  groups: Group[];
}

export interface Group {
  id: number;
  name: string;
}

export const groupEndpoint = createEndpoint()
  .withPath("group")
  .withMethod("GET")
  .withResponse<GroupData>();

export function useGroups() {
  const query = useAPIQuery(groupEndpoint, undefined, {
    refetchInterval: 5 * 60000,
  });

  const groups = useMemo(() => {
    return (
      query.data?.groups.slice().sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) ?? []
    );
  }, [query.data]);
  return {
    query,
    groups,
  };
}
