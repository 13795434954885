import { useDatePickerContext } from "~/components/DatePicker/DatePickerContext";
import { Box, Pressable, Text } from "native-base";
import { useDay } from "@datepicker-react/hooks";

export interface DayProps {
  dayLabel: string;
  date: Date;
}

export function Day({ dayLabel, date }: DayProps) {
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    isStartDate,
    isEndDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useDatePickerContext();

  const { isSelected, isSelectedStartOrEnd, disabledDate, onClick } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
  });

  return (
    <Pressable
      onPress={onClick}
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      flexShrink={0}
      width={`${100 / 7}%`}
      opacity={disabledDate ? 0.2 : 1}
    >
      {isSelected && (
        <Box
          justifyContent="center"
          style={{
            position: "absolute",
            top: 0,
            left: isStartDate(date) ? "49%" : 0,
            right: isEndDate(date) ? "49%" : 0,
            bottom: 0,
          }}
        >
          <Box h="8" w="full" bg="primary.100" _dark={{ bg: "primary.900" }} />
        </Box>
      )}
      <Box
        bg={isSelectedStartOrEnd ? "primary.400" : "transparent"}
        _dark={{ bg: isSelectedStartOrEnd ? "primary.700" : "transparent" }}
        rounded={isSelectedStartOrEnd ? "full" : "0"}
        m="1"
        h="8"
        w={isSelectedStartOrEnd ? "8" : "full"}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontWeight={isSelectedStartOrEnd ? "bold" : "normal"} textAlign="center">
          {dayLabel}
        </Text>
      </Box>
    </Pressable>
  );
}
