import { useQuery, UseQueryOptions } from "react-query";
import { mutatedResponseSymbol, type Endpoint } from "./endpoint";
import fjss from "fast-json-stable-stringify";
import { z } from "zod";
import { ApiV2Error } from "./apiError";
import { useAtom } from "jotai";
import { loginAtom } from "~/common/storage/atoms";
import { apiFetch } from "~/common/api/apiFetch";
import { useToast } from "../hooks/useToast";

const apiBasePath = process.env.API_BASE_PATH;

if (!apiBasePath) {
  throw "Missing API Base Path";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAPIQuery<E extends Endpoint<any, any>, R extends z.infer<E["params"]>>(
  endpoint: E,
  body: R,
  options?: Omit<
    UseQueryOptions<E[typeof mutatedResponseSymbol], ApiV2Error<z.infer<E["params"]>>>,
    "mutationKey" | "mutationFn"
  >
) {
  const [login, setLogin] = useAtom(loginAtom);
  const toast = useToast();
  return useQuery<E[typeof mutatedResponseSymbol], ApiV2Error<z.infer<E["params"]>>>(
    [endpoint, fjss(body), fjss(login)],
    async () => {
      return await apiFetch(apiBasePath!, endpoint, body, login, () => {
        setLogin(undefined);
        toast.show({
          title: "Session expired.",
          description: "Please log in again.",
          status: "danger",
          duration: 3000,
        });
      });
    },
    options
  );
}
