import { IInputProps, Input } from "native-base";
import { FieldValues, FieldPath, useController } from "react-hook-form";
import { ControlProps } from ".";

export function FormInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props: ControlProps<Omit<IInputProps, "onBlur" | "onChangeText" | "value">, TFieldValues, TName>
) {
  const controller = useController(props);

  return (
    <Input
      {...props}
      onBlur={controller.field.onBlur}
      onChangeText={(value) => controller.field.onChange(value)}
      value={controller.field.value}
    />
  );
}
