import { mmkv } from "../storage/mmkv";

interface Log {
  timestamp: number;
  data: string;
}

class LogController {
  enabled: boolean = mmkv.getBoolean(`@tin-app/logs_enabled`);
  logs: Log[] = [];
  constructor() {
    this.logs = [];
    this.toggleEnabledLogFunction();
  }

  setEnabled(enabled: boolean): void {
    this.enabled = enabled;
    this.toggleEnabledLogFunction();
    mmkv.set(`@tin-app/logs_enabled`, enabled);
  }

  toggleEnabledLogFunction(): void {
    this.log = this.enabled ? this.internal_log : null;
  }

  log: ((data: string, timestamp?: number) => void) | null = null;

  internal_log(data: string, timestamp?: number): void {
    this.addLog({
      timestamp: timestamp ?? Date.now(),
      data,
    });
  }

  private addLog(log: Log): void {
    if (!this.enabled) return;
    this.logs.push(log);
    if (this.logs.length > 10) {
      this.save();
    }
  }

  save(): void {
    mmkv.set(`@tin-app/logs/${Date.now()}`, JSON.stringify(this.logs));
    this.logs = [];
    this.cleanup();
  }

  private getLogKeys(): string[] {
    return mmkv.getAllKeys().filter((i) => i.startsWith("@tin-app/logs/"));
  }

  cleanup(removeAll = false): void {
    const keys = this.getLogKeys();
    const removeBefore = Date.now() - 1000 * 60 * 60 * 24; // 1 day
    for (const key of keys) {
      const logGroupTimestamp = Number(key.split("/")[2]);
      if (removeAll || Number.isNaN(logGroupTimestamp) || logGroupTimestamp < removeBefore) {
        mmkv.delete(key);
      }
    }
    if (removeAll) {
      this.logs = [];
    }
  }

  getAllLogs(): Log[] {
    const logs: Log[] = [];
    const keys = this.getLogKeys();
    for (const key of keys) {
      const logGroup = mmkv.getString(key);
      if (!logGroup) continue;
      try {
        const logGroupLogs: Log[] = JSON.parse(logGroup);
        logs.push(...logGroupLogs);
      } catch (e) {
        logs.push({
          timestamp: Date.now(),
          data: `Error parsing log group: ${logGroup}`,
        });
      }
    }
    logs.push(...this.logs);
    return logs;
  }

  getJSON() {
    const logs = this.getAllLogs();
    if (logs.length === 0) return null;
    return JSON.stringify(logs, null, 2);
  }
}

export const logController = new LogController();
