/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from "@hookform/resolvers/zod";
import { UseFormProps, useForm } from "react-hook-form";
import { ZodType, z } from "zod";

export function useValidatedForm<T extends ZodType<any, any, any>>(
  props: Omit<UseFormProps, "resolver"> & { schema: T }
) {
  return useForm<z.infer<T>>({
    ...props,
    resolver: zodResolver(props.schema),
  } as any);
}
