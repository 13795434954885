import {
  Alert,
  Box,
  Button,
  Checkbox,
  Heading,
  HStack,
  Icon,
  IconButton,
  ScrollView,
  Stack,
  Text,
  VStack,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router";
import { cacheDirectory, documentDirectory } from "expo-file-system";
import { zipWithPassword } from "./zip";
import { shareAsync } from "expo-sharing";
import { logController } from "~/common/logs/logController";
import { Platform, Share } from "react-native";
import { Experiments } from "./experiments";
import { useToast } from "~/common/hooks/useToast";
import { useAtom, WritableAtom } from "jotai";
import { atomWithMMKV } from "~/common/storage/atoms";

function DataExporting() {
  const [exporting, setExporting] = useState(false);
  const toast = useToast();
  return (
    <Stack bg="primary.50" _dark={{ bg: "dark.50" }} shadow="2" p="4" rounded="md" space="2">
      <Box>
        <Heading mb={1} fontSize="md">
          Data Exporting
        </Heading>
        <Button
          disabled={exporting}
          isLoading={exporting}
          isLoadingText="Exporting..."
          onPress={async () => {
            try {
              setExporting(true);
              const targetPath = `${cacheDirectory!}/export-${Date.now()}.zip`;
              logController.log?.(`[Settings:DataExport] Exporting to ${targetPath}... `);
              const sourcePath = documentDirectory!;
              // This password should at least stop the average person from viewing the data
              await zipWithPassword(sourcePath, targetPath, "notagoodpassword");
              logController.log?.("[Settings:DataExport] Exported, Sharing...");
              await shareAsync(targetPath);
              logController.log?.("[Settings:DataExport] Shared.");
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
              logController.log?.(`[Settings:DataExport] Error: ${e.toString()}`);
              toast.show({
                title: `Failed to export data: ${e}`,
                status: "danger",
                duration: 2000,
              });
            }
          }}
        >
          Export Data
        </Button>
      </Box>
    </Stack>
  );
}

function Logging() {
  const [, rerender] = useReducer((i) => {
    return i + 1;
  }, 0);
  const toast = useToast();
  return (
    <Stack bg="primary.50" _dark={{ bg: "dark.50" }} shadow="2" p="4" rounded="md" space="2">
      <Box>
        <Heading mb={1} fontSize="md">
          Logging
        </Heading>
        <Checkbox
          m="2"
          alignSelf="stretch"
          aria-label={logController.enabled ? "Disable debug logging" : "Enable debug logging"}
          value=""
          onChange={() => {
            logController.setEnabled(!logController.enabled);
            rerender();
          }}
          isChecked={logController.enabled}
        >
          Debug Logging
        </Checkbox>
        <Button
          onPress={async () => {
            try {
              const data = logController.getJSON();
              if (data === null) {
                toast.show({
                  title: "No Logs to Export",
                  status: "info",
                  duration: 2000,
                });
                return;
              }

              await Share.share({
                message: data,
                title: "TVA Logs",
              });
            } catch {
              toast.show({
                title: "Failed to export logs",
                status: "danger",
                duration: 2000,
              });
            }
          }}
        >
          Export Logs
        </Button>
      </Box>
    </Stack>
  );
}

function AtomToggle(props: { label: string; atom: WritableAtom<boolean, boolean> }) {
  const [value, setValue] = useAtom(props.atom);
  return (
    <Checkbox
      m="2"
      alignSelf="stretch"
      value=""
      onChange={() => {
        setValue(!value);
      }}
      isChecked={value}
    >
      {props.label}
    </Checkbox>
  );
}

export const disableBottomSheetAtom = atomWithMMKV<boolean>("disableBottomSheet", false);
export const disableMapsAtom = atomWithMMKV<boolean>("disableMaps", false);
export const disableAssetListAtom = atomWithMMKV<boolean>("disableAssetList", false);
export const disableAssetIconsAtom = atomWithMMKV<boolean>("disableAssetIcons", false);
export const googleMapsAtom = atomWithMMKV<boolean>("googleMaps", false);
export const primaryColorAtom = atomWithMMKV<string>("primaryColor", "green");

function ExtraOptions() {
  const [primaryColor, setPrimaryColor] = useAtom(primaryColorAtom);
  return (
    <Stack bg="primary.50" _dark={{ bg: "dark.50" }} shadow="2" p="4" rounded="md" space="2">
      <Box>
        <Heading mb={1} fontSize="md">
          Extra Options
        </Heading>
        <AtomToggle label="Disable Maps" atom={disableMapsAtom} />
        <AtomToggle label="Disable Bottom Sheet" atom={disableBottomSheetAtom} />
        <AtomToggle label="Disable Asset List" atom={disableAssetListAtom} />
        <AtomToggle label="Disable Asset Icons" atom={disableAssetIconsAtom} />
        {Platform.OS === "android" && (
          <AtomToggle label="Use Google Maps (Experimental)" atom={googleMapsAtom} />
        )}
        <HStack flexWrap="wrap">
          {[
            "light",
            "rose",
            "pink",
            "fuchsia",
            "purple",
            "violet",
            "indigo",
            "blue",
            "lightBlue",
            "darkBlue",
            "cyan",
            "teal",
            "emerald",
            "green",
            "lime",
            "yellow",
            "amber",
            "orange",
            "red",
            "warmGray",
            "trueGray",
            "gray",
            "coolGray",
            "blueGray",
          ].map((i) => (
            <Button
              disabled={primaryColor === i}
              colorScheme={i}
              onPress={() => setPrimaryColor(i)}
            >
              {i}
            </Button>
          ))}
        </HStack>
      </Box>
    </Stack>
  );
}

export function DebuggingScreen() {
  const navigate = useNavigate();
  return (
    <ScrollView
      bg="primary.100"
      _dark={{ bg: "dark.100" }}
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <VStack
        width="500px"
        maxWidth="100%"
        alignSelf="center"
        space="2"
        safeArea
        p="2"
        flexGrow="1"
      >
        <HStack alignItems="center">
          <IconButton
            onPress={() => navigate(-1)}
            icon={<Icon as={MaterialCommunityIcons} name="chevron-left" m={-1} size="xl" />}
          />
          <Heading>Debugging Tools</Heading>
        </HStack>
        <Alert status="danger" colorScheme="danger">
          <VStack space={2} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
              <HStack flexShrink={1} space={2} alignItems="center">
                <Alert.Icon />
                <Text fontSize="md" fontWeight="medium" color="coolGray.800">
                  Danger Ahead
                </Text>
              </HStack>
            </HStack>
            <Box pl="6">
              <Text color="coolGray.600">
                These tools are for debugging and testing purposes only. If you don't know what
                you're doing, you should not use them.
              </Text>
            </Box>
          </VStack>
        </Alert>

        <Logging />

        {Platform.OS !== "web" && <DataExporting />}

        <ExtraOptions />

        {__DEV__ && <Experiments />}
      </VStack>
    </ScrollView>
  );
}
