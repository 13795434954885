import {
  Alert,
  Box,
  Button,
  FormControl,
  HStack,
  Icon,
  IconButton,
  ScrollView,
  Stack,
  Text,
  useColorMode,
  VStack,
} from "native-base";
import { FormInput } from "~/components/Controls/Input";
import { useValidatedForm } from "~/common/hooks/useValidatedForm";
import { useAPIMutation } from "~/common/api/useAPIMutation";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { CacheFixedImage } from "~/components/Images/CacheFixedImage";
import Animated, { useAnimatedStyle, withSpring } from "react-native-reanimated";
import { useNavigate } from "react-router";
import { useKeyboardHeight } from "~/common/hooks/useKeyboardHeight";
import { loginAtom } from "~/common/storage/atoms";
import { loginEndpoint } from "~/features/auth/api";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const AnimatedStack = Animated.createAnimatedComponent(Stack);

export function LoginScreen() {
  const [, setLogin] = useAtom(loginAtom);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useValidatedForm({
    schema: loginEndpoint.params,
  });
  const { colorMode } = useColorMode();
  const loginMutation = useAPIMutation(
    loginEndpoint,
    {},
    {
      onSuccess: (data) => {
        setLogin({
          username: data.username,
          token: data.accessToken,
          clientCode: data.clientCode,
        });
      },
    }
  );
  const { keyboardHeight } = useKeyboardHeight();
  const keyboardAvoidingStyle = useAnimatedStyle(() => {
    return {
      marginBottom: withSpring(keyboardHeight.value, {
        overshootClamping: true,
      }),
    };
  }, [keyboardHeight]);
  return (
    <Box bg="primary.100" _dark={{ bg: "dark.100" }} flex={1}>
      <CacheFixedImage
        alt="Background"
        source={
          colorMode === "dark"
            ? require("~/assets/misc/background_pin_dark.png")
            : require("~/assets/misc/background_pin_light.png")
        }
        opacity={0.1}
        position="absolute"
        width="400px"
        height="400px"
        resizeMode="contain"
        top="50px"
        right="-120px"
      />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        flex={1}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Box flexGrow={1} p="4" safeArea justifyContent="center" alignItems="center">
          <AnimatedStack
            width={400}
            maxWidth="100%"
            bg="primary.50"
            _dark={{ bg: "dark.50" }}
            shadow="2"
            p="4"
            rounded="md"
            space="4"
            style={keyboardAvoidingStyle}
          >
            {loginMutation.isError && (
              <Alert w="100%" status="warning">
                <HStack w="100%" space={2}>
                  <Alert.Icon mt="1" />
                  <VStack flex={1}>
                    <Text fontSize="md" color="coolGray.800">
                      {loginMutation.error.message}
                    </Text>
                    {Object.keys(loginMutation.error.fields ?? {}).length > 0 && (
                      <Text fontSize="sm" color="coolGray.800">
                        {Object.values(loginMutation.error.fields ?? {})
                          .flat()
                          .join("\n")}
                      </Text>
                    )}
                  </VStack>
                </HStack>
              </Alert>
            )}

            <FormControl isRequired isInvalid={"clientCode" in formState.errors}>
              <FormControl.Label>Client Code</FormControl.Label>
              <FormInput control={control} name="clientCode" returnKeyType="next" />
              <FormControl.ErrorMessage>
                {formState.errors.clientCode?.message}
              </FormControl.ErrorMessage>
            </FormControl>

            <Box height="2" />

            <FormControl isRequired isInvalid={"username" in formState.errors}>
              <FormControl.Label>Username</FormControl.Label>
              <FormInput
                control={control}
                name="username"
                autoCompleteType="username"
                returnKeyType="next"
              />
              <FormControl.ErrorMessage>
                {formState.errors.username?.message}
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={"password" in formState.errors}>
              <FormControl.Label>Password</FormControl.Label>
              <FormInput
                control={control}
                name="password"
                type={showPassword ? "text" : "password"}
                autoCompleteType="password"
                returnKeyType="go"
                secure={!showPassword}
                rightElement={
                  <IconButton
                    onPress={() => setShowPassword(!showPassword)}
                    icon={
                      <Icon as={MaterialCommunityIcons} name={showPassword ? "eye" : "eye-off"} />
                    }
                    borderRadius="full"
                  />
                }
              />
              <FormControl.ErrorMessage>
                {formState.errors.password?.message}
              </FormControl.ErrorMessage>
            </FormControl>
            <Button
              isLoading={loginMutation.isLoading}
              isLoadingText="Logging in..."
              onPress={handleSubmit((data) => {
                loginMutation.mutate(data);
              })}
            >
              Login
            </Button>
            <Button variant="ghost" onPress={() => navigate("/forgotpassword")}>
              Forgot Password?
            </Button>
          </AnimatedStack>
        </Box>
      </ScrollView>
    </Box>
  );
}
