import { useMutation, UseMutationOptions } from "react-query";
import { mutatedResponseSymbol, type Endpoint } from "./endpoint";
import fjss from "fast-json-stable-stringify";
import { z } from "zod";
import { ApiV2Error } from "./apiError";
import { useAtom } from "jotai";
import { loginAtom } from "~/common/storage/atoms";
import { apiFetch } from "~/common/api/apiFetch";
import { useToast } from "../hooks/useToast";

const apiBasePath = process.env.API_BASE_PATH;

if (!apiBasePath) {
  throw "Missing API Base Path";
}

export function useAPIMutation<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Endpoint<any, any>,
  R extends Partial<z.infer<E["params"]>>
>(
  endpoint: E,
  baseBody?: R,
  options?: Omit<
    UseMutationOptions<
      E[typeof mutatedResponseSymbol],
      ApiV2Error<z.infer<E["params"]>>,
      Omit<z.infer<E["params"]>, keyof R> &
        Partial<Pick<z.infer<E["params"]>, keyof R & keyof z.infer<E["params"]>>>
    >,
    "mutationKey" | "mutationFn"
  >
) {
  const [login, setLogin] = useAtom(loginAtom);
  const toast = useToast();
  return useMutation<
    E[typeof mutatedResponseSymbol],
    ApiV2Error<z.infer<E["params"]>>,
    Omit<z.infer<E["params"]>, keyof R> &
      Partial<Pick<z.infer<E["params"]>, keyof R & keyof z.infer<E["params"]>>>
  >(
    [endpoint, fjss(baseBody)],
    async (data) => {
      return await apiFetch(apiBasePath!, endpoint, { ...baseBody, ...data }, login, () => {
        setLogin(undefined);
        toast.show({
          title: "Session expired.",
          description: "Please log in again.",
          status: "danger",
          duration: 3000,
        });
      });
    },
    options
  );
}
