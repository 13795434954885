import { Button, Modal } from "native-base";
import { useState } from "react";
import { DateRangePicker } from "~/components/DatePicker/DateRangePicker";
import { useWindowDimensions } from "react-native";
import { useToast } from "~/common/hooks/useToast";

export interface DateRangePickerModalProps {
  initialStartDate?: Date;
  initialEndDate?: Date;
  open: boolean;
  onClose: () => void;
  onSelect: (startDate: Date, endDate: Date) => void;
}

export function DateRangePickerModal(props: DateRangePickerModalProps) {
  const [startDate, setStartDate] = useState<Date | null>(props.initialStartDate ?? new Date());
  const [endDate, setEndDate] = useState<Date | null>(props.initialEndDate ?? new Date());
  const toast = useToast();
  const windowSize = useWindowDimensions();
  const isLarge = windowSize.width > 800;
  return (
    <Modal isOpen={props.open} onClose={() => props.onClose()} size={isLarge ? "lg" : "md"}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Select Range</Modal.Header>
        <Modal.Body>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onDatesChange={(startDate, endDate) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            numberOfMonths={isLarge ? 2 : 1}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              variant="ghost"
              colorScheme="blueGray"
              onPress={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              onPress={() => {
                if (!startDate) {
                  toast.show({
                    title: "Please select a start and end date",
                    status: "danger",
                  });
                  return;
                }
                if (!endDate) {
                  props.onSelect(startDate, startDate);
                  return;
                }
                props.onSelect(startDate, endDate);
              }}
            >
              Select
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
