import Animated, { interpolateColor, useAnimatedStyle } from "react-native-reanimated";
import { BlurView } from "expo-blur";
import React, { useMemo } from "react";
import { BottomSheetBackgroundProps } from "@gorhom/bottom-sheet";
import { useColorMode, useToken } from "native-base";

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

export const BlurredBottomSheetBackground: React.FC<
  Pick<BottomSheetBackgroundProps, "style" | "animatedIndex">
> = ({ style, animatedIndex }) => {
  const { colorMode } = useColorMode();
  const lightGreen = useToken("colors", "primary.200");
  const darkGreen = useToken("colors", "dark.100");

  const lightContainerAnimatedStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(animatedIndex.value, [0, 1], [lightGreen + "88", lightGreen]),
  }));
  const darkContainerAnimatedStyle = useAnimatedStyle(() => ({
    backgroundColor: interpolateColor(animatedIndex.value, [0, 1], [darkGreen + "aa", darkGreen]),
  }));
  const backgroundStyle = useMemo(
    () => [
      { flex: 1 },
      colorMode === "dark" ? darkContainerAnimatedStyle : lightContainerAnimatedStyle,
    ],
    [style, lightContainerAnimatedStyle, darkContainerAnimatedStyle, colorMode]
  );

  return (
    <AnimatedBlurView
      intensity={50}
      tint={colorMode === "dark" ? "dark" : "light"}
      pointerEvents="none"
      key={colorMode}
      style={style}
    >
      <Animated.View style={backgroundStyle} />
    </AnimatedBlurView>
  );
};
