import { useRef, useState } from "react";

export function useSearch(timeout: number): [string, string, (x: string) => void] {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function onValue(val: string) {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setValue(val);
    timeoutRef.current = setTimeout(() => {
      return setSearch(val);
    }, timeout);
  }
  return [value, search, onValue];
}
