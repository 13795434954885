import { END_DATE, START_DATE, useDatepicker } from "@datepicker-react/hooks";
import { useState } from "react";
import { Month } from "./Month";
import { DatePickerContext } from "~/components/DatePicker/DatePickerContext";
import { HStack } from "native-base";

export interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onDatesChange: (startDate: Date | null, endDate: Date | null) => void;
  numberOfMonths?: number;
}

export function DateRangePicker(props: DateRangePickerProps) {
  const [focusedInput, setFocusedInput] = useState<typeof START_DATE | typeof END_DATE>(START_DATE);
  const datePicker = useDatepicker({
    startDate: props.startDate,
    endDate: props.endDate,
    focusedInput: focusedInput,
    maxBookingDate: new Date(),
    onDatesChange: (data) => {
      props.onDatesChange(data.startDate, data.endDate);
      setFocusedInput(data.focusedInput ?? START_DATE);
    },
    numberOfMonths: props.numberOfMonths ?? 1,
  });
  return (
    <DatePickerContext.Provider value={datePicker}>
      <HStack space="2">
        {datePicker.activeMonths.map((month, n, a) => (
          <Month
            prev={n === 0 ? datePicker.goToPreviousMonthsByOneMonth : undefined}
            next={n === a.length - 1 ? datePicker.goToNextMonthsByOneMonth : undefined}
            key={`${month.year}-${month.month}`}
            year={month.year}
            month={month.month}
            firstDayOfWeek={datePicker.firstDayOfWeek}
          />
        ))}
      </HStack>
    </DatePickerContext.Provider>
  );
}
