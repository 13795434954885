import { z } from "zod";
import { createEndpoint } from "~/common/api/endpoint";
import { ApiV2AssetStatus, ApiV2AssetType, ApiV2Formatting, ApiV2Point, ApiV2Zone } from "./common";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { useAPIQuery } from "~/common/api/useAPIQuery";
import { useMemo } from "react";

export interface ApiV2LocateResponse {
  assets: ApiV2Asset[];
  zones: ApiV2Zone[];
  group: ApiV2Group;
  formatting: ApiV2Formatting;
  trackAvailable: boolean;
}

export interface ApiV2Group {
  id: number;
  name: string;
}

export interface ApiV2Asset {
  id: number;
  type: ApiV2AssetType;
  name: string;
  driver: string | null;
  status: ApiV2AssetStatus | null;
  ptoOn: boolean | null;
  zone: string | null;
  address: string | null;
  speed: number | null;
  heading: number | null;
  statusSince: string | null;
  comment: string;
  updatedAt: number;
  distanceToday: number;
  location: ApiV2Point | null;
}

export const locateEndpoint = createEndpoint()
  .withPath("locate/v2")
  .withMethod("GET")
  .withParams(
    z.object({
      groupId: z.number().optional(),
    })
  )
  .withResponse<ApiV2LocateResponse>();

export function useLocate() {
  // Selected Group
  const { group, selectedAsset } = useLocateOptions();

  // Data Loading
  const query = useAPIQuery(
    locateEndpoint,
    {
      groupId: group === "all" ? undefined : Number(group),
    },
    {
      refetchInterval: selectedAsset ? 15000 : 60000,
      keepPreviousData: true,
    }
  );
  const assets = useMemo(() => {
    const assets = query.data?.assets ?? [];
    // Sort assets by name
    return assets.slice().sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }, [query.data]);

  return {
    query,
    assets: assets,
    selectedAsset: assets.find((i) => i.id === selectedAsset),
    formatting: query.data?.formatting,
  };
}
