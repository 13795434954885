import { ApiV2ZoneDisplay, ApiV2Zone } from "~/features/locate/api/common";

export function getZoneIcon({ display }: Pick<ApiV2Zone, "display">) {
  switch (display) {
    case ApiV2ZoneDisplay.PoiBlue:
      return {
        source: require("~/assets/zones/blue.png"),
        angle: 0,
      };
    case ApiV2ZoneDisplay.PoiGreen:
      return {
        source: require("~/assets/zones/green.png"),
        angle: 0,
      };
    case ApiV2ZoneDisplay.PoiRed:
      return {
        source: require("~/assets/zones/red.png"),
        angle: 0,
      };
    case ApiV2ZoneDisplay.PoiYellow:
      return {
        source: require("~/assets/zones/yellow.png"),
        angle: 0,
      };
  }
  return null;
}
