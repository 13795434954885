import { ImageSourcePropType } from "react-native";

export interface ChangelogInterface {
  version: number;
  title: string;
  description: string;
  icon?: string;
  iconColor?: string;
  image?: ImageSourcePropType;
  imageRounded?: boolean;
}

// Insert new changelogs at the top of the list, with an updated version number
// You can put multiple changelog entries for the same version number
export const allChangelogs: ChangelogInterface[] = [
  {
    version: 1.00_00,
    icon: "rocket",
    iconColor: "green.500",
    title: "Initial Release",
    description: "This is the first release of the new app.",
  },
];
