import { ReactNode, useContext } from "react";
import { UNSAFE_RouteContext } from "react-router";

export interface PanelsProps {
  left: ReactNode;
  leftKey: string;
  right?: ReactNode;
  rightKey?: string;
}

export const Panels: (props: PanelsProps) => null = () => null;

export function usePanelData(): PanelsProps | null {
  const context = useContext(UNSAFE_RouteContext);
  if (context?.outlet?.props?.children?.type === Panels) {
    return context?.outlet?.props?.children?.props ?? null;
  }
  return null;
}
