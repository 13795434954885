import {
  ForwardedRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { MapViewRef } from "~/components/Maps/Common";
import { useTrack } from "~/features/locate/api/track";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { useLocate } from "~/features/locate/api/locate";
import { useAtom, WritableAtom } from "jotai";

export const MapPositionController = forwardRef(function (
  {
    mapRef,
    mapMovedAtom,
    paddingLeft,
    paddingRight,
    paddingBottom,
  }: {
    mapRef: RefObject<MapViewRef | null>;
    mapMovedAtom: WritableAtom<boolean, boolean>;
    paddingLeft: number;
    paddingRight: number;
    paddingBottom: number;
  },
  ref: ForwardedRef<() => void>
) {
  const [mapMoved, setMapMoved] = useAtom(mapMovedAtom);

  const { assets } = useLocate();

  const { query: trackQuery } = useTrack();

  // Parameters
  const { selectedTrack, selectedAsset } = useLocateOptions();

  // Camera Position
  const updateCameraPosition = useCallback(
    (moveInstantly = false) => {
      // Fit Track Data if on Asset Track Page
      if (trackQuery.data && trackQuery.data.segments.length > 0) {
        // Fit Track Section if selected
        if (selectedTrack !== undefined) {
          const entry = trackQuery.data.entries.find((i) => i.id === selectedTrack);
          // Zoom to Stop if stop selected
          if (entry?.number) {
            mapRef.current?.goToCoordinate({
              latitude: entry.latitude,
              longitude: entry.longitude,
              zoom: 15,
              altitude: 6000,
              animated: !moveInstantly,
            });
            return;
          }

          if (entry) {
            mapRef.current?.fitCoordinates({
              coordinates: trackQuery.data.segments
                .filter((i) => i.entryId === selectedTrack)
                .flatMap((i) => i.points)
                .map((i) => [i.latitude, i.longitude]),
              padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              },
              animated: !moveInstantly,
            });
            return;
          }
        }

        mapRef.current?.fitCoordinates({
          coordinates: trackQuery.data.segments
            .flatMap((i) => i.points)
            .map((i) => [i.latitude, i.longitude]),
          padding: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          },
          animated: !moveInstantly,
        });
        return;
      }
      // View Asset if on Asset Details Page
      if (selectedAsset !== undefined) {
        const asset = assets.find((i) => i.id === selectedAsset);
        if (asset?.location) {
          mapRef.current?.goToCoordinate({
            latitude: asset.location?.latitude ?? 0,
            longitude: asset.location?.longitude ?? 0,
            zoom: 12,
            altitude: 10000,
            animated: !moveInstantly,
          });
          return;
        }
      }
      // Otherwise, View all Assets
      mapRef.current?.fitCoordinates({
        coordinates: assets
          .filter((i) => i.location?.latitude !== undefined && i.location?.longitude !== undefined)
          .map((i) => [i.location!.latitude, i.location!.longitude]),
        padding: {
          top: 50,
          right: 100,
          bottom: 50,
          left: 50,
        },
        animated: !moveInstantly,
      });
    },
    [selectedAsset, selectedTrack, assets, trackQuery.data]
  );

  // Update Camera Position when assets first load
  useEffect(() => {
    updateCameraPosition(true);
    setMapMoved(false);
  }, [
    !!assets.length, // Assets Loaded
  ]);

  // Update Camera Position when selections changed or track changed
  useEffect(() => {
    updateCameraPosition();
    setMapMoved(false);
  }, [selectedAsset, trackQuery.dataUpdatedAt, selectedTrack]);

  // Update Camera Position when assets moved or track changed
  // only if map hasn't been moved manually
  useEffect(() => {
    if (!mapMoved) {
      updateCameraPosition();
    }
  }, [assets]);

  // Update Camera Position when padding changed
  // only if map hasn't been moved manually
  useEffect(() => {
    if (!mapMoved) {
      updateCameraPosition();
    }
  }, [paddingLeft, paddingRight, paddingBottom]);

  useLayoutEffect(() => {
    if (ref && typeof ref === "function") {
      ref(updateCameraPosition);
    } else if (ref) {
      ref.current = updateCameraPosition;
    }
  }, [updateCameraPosition]);

  return null;
});
