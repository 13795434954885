import { z } from "zod";

export const responseSymbol = Symbol();
export const mutatedResponseSymbol = Symbol();
export interface Endpoint<Response, Schema extends z.Schema, MutatedResponse = Response> {
  path: string;
  method: "GET" | "POST";
  params: Schema;
  paramsMutator?: (params: z.infer<Schema>) => unknown;
  [responseSymbol]: Response;
  [mutatedResponseSymbol]: MutatedResponse;
  responseMutator?: (response: Response, params: z.infer<Schema>) => MutatedResponse;
  withPath(path: string): Endpoint<Response, Schema, MutatedResponse>;
  withMethod(method: "GET" | "POST"): Endpoint<Response, Schema, MutatedResponse>;
  withParams<NewSchema extends z.Schema>(
    params: NewSchema
  ): Endpoint<Response, NewSchema, MutatedResponse>;
  withParamsMutator(
    mutator: (params: z.infer<Schema>) => z.infer<Schema>
  ): Endpoint<Response, Schema, MutatedResponse>;
  withResponse<NewResponse>(): Endpoint<NewResponse, Schema, NewResponse>;
  withResponseMutator<NewMutatedResponse>(
    mutator: (response: Response, params: z.infer<Schema>) => NewMutatedResponse
  ): Endpoint<Response, Schema, NewMutatedResponse>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createEndpoint(): Endpoint<any, z.ZodVoid, any> {
  return {
    path: "",
    method: "GET",
    params: z.void(),
    [responseSymbol]: "",
    [mutatedResponseSymbol]: "",
    withPath(path) {
      return { ...this, path };
    },
    withMethod(method) {
      return { ...this, method };
    },
    withParams(params) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...this, params } as any;
    },
    withResponse<Response>() {
      return { ...this, [responseSymbol]: "" as unknown as Response, responseMutator: undefined };
    },
    withResponseMutator<NewMutatedResponse>(mutator: (data: Response) => NewMutatedResponse) {
      return {
        ...this,
        responseMutator: mutator,
      };
    },
    withParamsMutator(mutator) {
      return { ...this, paramsMutator: mutator };
    },
  };
}
