import { VStack, Heading, HStack, Pressable, Icon, Input } from "native-base";
import React from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { PanelScrollView } from "~/components/BottomSheet/BottomSheetCompatibleViews";
import { useSearch } from "~/common/hooks/useSearch";
import Fuse from "fuse.js";
import { useGroups } from "../api/group";
import { PanelHeader } from "~/components/BottomSheet/PanelHeader";

export function GroupsPanel() {
  const { groups } = useGroups();
  const navigate = useNavigate();

  const [value, search, onValue] = useSearch(300);

  const filteredGroups = useMemo(() => {
    if (!search) {
      return groups;
    }
    const fuse = new Fuse(groups, {
      keys: ["name"],
      threshold: 0.3,
    });
    return fuse.search(search).map((i) => i.item);
  }, [groups, search]);

  return (
    <PanelScrollView header={<PanelHeader back title="Select Group" />}>
      <VStack>
        <Input
          value={value}
          onChangeText={(val) => onValue(val)}
          size="md"
          m="2"
          InputLeftElement={
            <Icon as={<MaterialCommunityIcons name="magnify" />} size="md" ml="2" />
          }
          placeholder="Search"
        />
        {filteredGroups.map((group) => {
          return (
            <Pressable key={group.id} onPress={() => navigate(`/locate/${group.id}`)}>
              <HStack space="2" p="2" alignItems="center">
                <Heading fontSize="md">{group.name}</Heading>
              </HStack>
            </Pressable>
          );
        })}
      </VStack>
    </PanelScrollView>
  );
}
