import { StatusBar } from "expo-status-bar";
import { CheckIcon, extendTheme, NativeBaseProvider, useColorMode, useToken } from "native-base";
import { useEffect, useMemo } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Navigation } from "~/features/navigation/navigation";

import { QueryClient, QueryClientProvider } from "react-query";

import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createAsyncStoragePersistor } from "react-query/createAsyncStoragePersistor-experimental";
import { themeAtom } from "./common/storage/atoms";
import { Router } from "./features/navigation/router";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { LogBox, Platform } from "react-native";
import { useAtom, useAtomValue } from "jotai";
import { mmkv } from "~/common/storage/mmkv";

import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { useSystemColorScheme } from "~/common/hooks/useSystemColorScheme";
import { primaryColorAtom } from "~/features/settings/debugging/screen";

LogBox.ignoreLogs(["PropType will be removed from React Native"]);

dayjs.extend(localizedFormat);
dayjs.locale("en-GB");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const asyncStoragePersistor = createAsyncStoragePersistor({
  storage: {
    getItem: async (key) => {
      const value = mmkv.getString(key);
      return value ?? null;
    },
    setItem: async (key, value) => {
      mmkv.set(key, value);
    },
    removeItem: async (key) => {
      mmkv.delete(key);
    },
  },
});

persistQueryClient({
  queryClient,
  persistor: asyncStoragePersistor,
});

function ThemeHandler() {
  const systemColorScheme = useSystemColorScheme();
  const { setColorMode } = useColorMode();
  const [themeSelection] = useAtom(themeAtom);
  const colorMode = useMemo(() => {
    return (themeSelection === "system" ? systemColorScheme : themeSelection) === "dark"
      ? "dark"
      : "light";
  }, [systemColorScheme, themeSelection]);
  useEffect(() => {
    setColorMode(colorMode);
  }, [colorMode]);
  const green = useToken("colors", "primary.500");
  return (
    <>
      {Platform.OS === "web" && (
        <style>
          {`
* {
  scrollbar-color: ${green} transparent;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
    background-color: ${green};
    background-clip: padding-box;
    border-radius: 16px;
    border: 4px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
    display:none;
}`}
        </style>
      )}
      <StatusBar style={colorMode === "dark" ? "light" : "dark"} />
    </>
  );
}

export default function App() {
  const primaryColorName = useAtomValue(primaryColorAtom);
  const theme = useMemo(() => {
    const defaultTheme = extendTheme({});
    const primaryColor =
      defaultTheme.colors[primaryColorName as keyof typeof defaultTheme.colors] ??
      defaultTheme.colors.green;
    return extendTheme({
      colors: {
        primary: primaryColor,
      },
      components: {
        Input: {
          defaultProps: {
            bg: "light.100",
            _dark: {
              bg: "dark.100",
            },
          },
        },
        Select: {
          defaultProps: {
            variant: "filled",
            bg: "light.200",
            _input: {
              _dark: {
                bg: "dark.100",
              },
            },
            _actionSheetContent: {
              _dark: { bg: "dark.200" },
              bg: "primary.300",
            },
            _item: {
              borderRadius: 4,
              bg: "primary.300",
              _dark: {
                bg: "dark.200",
                _hover: {
                  bg: "dark.200",
                },
                _focus: {
                  bg: "dark.200",
                },
                _pressed: {
                  bg: "dark.200",
                },
              },
              _hover: {
                bg: "primary.200",
              },
              _focus: {
                bg: "primary.200",
              },
              _pressed: {
                bg: "primary.200",
              },
            },
            _selectedItem: {
              bg: "primary.500",
              _dark: {
                bg: "primary.500",
              },
              endIcon: (
                <CheckIcon
                  color="black"
                  _dark={{
                    color: "white",
                  }}
                  alignSelf="center"
                  size={4}
                />
              ),
            },
          },
        },
      },
    });
  }, [primaryColorName]);
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <NativeBaseProvider theme={theme}>
              <ThemeHandler />
              <Navigation />
            </NativeBaseProvider>
          </Router>
        </QueryClientProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
}
