import {
  Box,
  Button,
  Heading,
  HStack,
  ScrollView,
  Select,
  Spacer,
  Stack,
  Text,
  VStack,
} from "native-base";
import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router";
import { loginAtom, themeAtom } from "~/common/storage/atoms";
import { useAtom } from "jotai";
import { Platform } from "react-native";
import { nativeApplicationVersion, nativeBuildVersion } from "expo-application";
import * as Updates from "expo-updates";
import { useToast } from "~/common/hooks/useToast";

export function SettingsScreen() {
  const [, incrementCounter] = useReducer<(i: number, n: number | null) => number>(
    (i, n) => n ?? i + 1,
    0
  );
  const navigate = useNavigate();
  const [colorMode, setColorMode] = useAtom(themeAtom);
  const [login, setLogin] = useAtom(loginAtom);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const toast = useToast();
  return (
    <ScrollView
      bg="primary.100"
      _dark={{ bg: "dark.100" }}
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <VStack
        width="500px"
        maxWidth="100%"
        alignSelf="center"
        space="2"
        safeArea
        p="2"
        flexGrow="1"
      >
        <HStack alignItems="center">
          <Heading>Settings</Heading>
        </HStack>

        <Stack bg="primary.50" _dark={{ bg: "dark.50" }} shadow="2" p="4" rounded="md">
          <Heading fontSize="xs">Client Code</Heading>
          <Text fontSize="md">{login?.clientCode ?? "Unknown"}</Text>
          <Heading mt="2" fontSize="xs">
            Username
          </Heading>
          <Text fontSize="md">{login?.username ?? "Unknown"}</Text>
          <Button
            mt="2"
            onPress={() => {
              setLogin(undefined);
            }}
          >
            Logout
          </Button>
        </Stack>

        <Stack bg="primary.50" _dark={{ bg: "dark.50" }} shadow="2" p="4" rounded="md" space="2">
          <Box>
            <Heading mb={1} fontSize="md">
              Theme
            </Heading>
            <Select
              size="lg"
              selectedValue={colorMode}
              onValueChange={(value) => setColorMode(value)}
            >
              <Select.Item key="system" value="system" label="System Default" />
              <Select.Item key="light" value="light" label="Light" />
              <Select.Item key="dark" value="dark" label="Dark" />
            </Select>
          </Box>
        </Stack>

        <Spacer />

        <Stack bg="primary.50" _dark={{ bg: "dark.50" }} shadow="2" p="4" rounded="md" space="2">
          <Box>
            <Heading mb={1} fontSize="md">
              About
            </Heading>
            <Text fontFamily={Platform.OS === "ios" ? "Menlo-Bold" : "monospace"}>
              {Platform.select({
                ios: "iOS ",
                android: "Android ",
                web: "Web ",
                default: "",
              })}
              App Version:{" "}
              {nativeApplicationVersion ??
                (Updates.manifest as { version?: string })?.version ??
                "Unknown"}{" "}
              ({Platform.OS === "web" ? "Web" : nativeBuildVersion ?? "Unknown"})
            </Text>
            <Text fontFamily={Platform.OS === "ios" ? "Menlo-Regular" : "monospace"}>
              OTA Channel {Updates?.releaseChannel ?? "N/A"} -{" "}
              <Text onPress={() => incrementCounter(null)}>
                Update {Updates?.updateId ?? "N/A"}
              </Text>
            </Text>
          </Box>

          {Platform.OS === "web" ? null : updateAvailable ? (
            <Button
              colorScheme="success"
              onPress={async () => {
                try {
                  await Updates.fetchUpdateAsync();
                } catch (e) {
                  toast.show({
                    title: "Failed to Download Update",
                    description: "Update failed to download. Please try again later.",
                    status: "danger",
                  });
                  return;
                }
                try {
                  await Updates.reloadAsync();
                } catch (e) {
                  toast.show({
                    title: "Failed to Install Update",
                    description: "Update failed to install. Please try again later.",
                    status: "danger",
                  });
                  return;
                }
              }}
            >
              Install Update
            </Button>
          ) : (
            <Button
              colorScheme="info"
              onPress={async () => {
                try {
                  const result = await Updates.checkForUpdateAsync();

                  toast.show({
                    title: result?.isAvailable ? "Update Available" : "No Update Available",
                    description: result?.manifest?.createdAt
                      ? `Release Date: ${new Date(result?.manifest?.createdAt).toLocaleString()}`
                      : undefined,
                    status: result?.isAvailable ? "success" : "info",
                  });
                  setUpdateAvailable(!!result?.isAvailable);
                } catch (e) {
                  toast.show({
                    title: "Failed to Check",
                    description: "Checking for Updates Failed",
                    status: "danger",
                  });
                }
              }}
            >
              Check for Updates
            </Button>
          )}

          <Button
            colorScheme="success"
            onPress={() => {
              navigate("/settings/changelogs");
            }}
          >
            View Previous Changelogs
          </Button>
        </Stack>

        <Button
          colorScheme="danger"
          onPress={() => {
            navigate("/settings/debugging");
          }}
        >
          Debugging Tools
        </Button>
      </VStack>
    </ScrollView>
  );
}
