import { z } from "zod";
import { createEndpoint } from "~/common/api/endpoint";

export const loginEndpoint = createEndpoint()
  .withPath("account/login/v2")
  .withMethod("POST")
  .withParams(
    z.object({
      username: z.string(),
      password: z.string(),
      clientCode: z.string(),
    })
  )
  .withResponse<{
    clientCode: string;
    username: string;
    accessToken: string;
  }>();

export const forgotPasswordEndpoint = createEndpoint()
  .withPath("account/forgotpassword")
  .withMethod("POST")
  .withParams(
    z.object({
      username: z.string(),
      clientCode: z.string(),
    })
  )
  .withResponse<{
    message: string;
  }>();
