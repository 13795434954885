import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, Heading, Text, HStack, Button, Icon } from "native-base";
import React, { useMemo, useRef } from "react";
import dayjs from "dayjs";
import { useLocate } from "~/features/locate/api/locate";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { PanelScrollView } from "~/components/BottomSheet/BottomSheetCompatibleViews";
import { getDirection, getStatusText } from "~/features/locate/display/asset";

import { showLocation } from "react-native-map-link";
import { Platform } from "react-native";
import { ApiV2Asset } from "~/features/locate/api/locate";
import { TrackDatePickerPopover } from "~/components/DatePicker/TrackDatePickerPopover";
import { PanelHeader } from "~/components/BottomSheet/PanelHeader";
import { AssetIcon } from "~/features/locate/display/assetIcons";
import { ApiV2AssetStatus } from "~/features/locate/api/common";

export function DetailsPanel() {
  const { selectedAsset } = useLocateOptions();
  const { query, selectedAsset: actualAsset, formatting } = useLocate();

  const previousAsset = useRef<ApiV2Asset | null>(null);
  const asset = useMemo(() => {
    if (!selectedAsset) {
      return actualAsset ?? previousAsset.current;
    }
    previousAsset.current = actualAsset ?? null;
    return actualAsset;
  }, [actualAsset, selectedAsset]);

  if (!asset) {
    return (
      <PanelScrollView header={<PanelHeader back title="Asset not found" />}>
        <VStack p="2" space="2">
          <Text>Please check the URL and try again.</Text>
        </VStack>
      </PanelScrollView>
    );
  }

  return (
    <>
      <PanelScrollView
        header={
          <PanelHeader
            back
            upperTitle="Following Location of"
            title={asset.name}
            subtitle={`Updated ${
              query.dataUpdatedAt ? `at ${dayjs(query.dataUpdatedAt).format("HH:mm")}` : "Never"
            }`}
            button={
              query.data?.trackAvailable ? (
                <TrackDatePickerPopover
                  trigger={(triggerProps) => {
                    return (
                      <Button
                        size="md"
                        startIcon={
                          <Icon as={MaterialCommunityIcons} name="map-marker-path" size="md" />
                        }
                        {...triggerProps}
                      >
                        Track
                      </Button>
                    );
                  }}
                />
              ) : undefined
            }
          />
        }
      >
        <VStack space="2" p="2">
          <HStack alignItems="center" space="2">
            <AssetIcon style={{ marginHorizontal: 4 }} size={6} asset={asset} />
            <VStack flex={1}>
              <Heading fontSize="md">
                {getStatusText(asset)}
                {asset.status === ApiV2AssetStatus.Driving && (
                  <Text>{` (${asset.speed} ${formatting?.speedUnit} ${
                    asset.heading ? getDirection(asset.heading) : ""
                  })`}</Text>
                )}
              </Heading>
              <Text>Since {asset.statusSince ?? "unknown"}</Text>
            </VStack>
          </HStack>

          {!!asset.driver && (
            <HStack alignItems="center" space="2">
              <Icon mx="1" as={MaterialCommunityIcons} name="account" size="6" />
              <VStack flex={1}>
                <Heading fontSize="md">{asset.driver}</Heading>
              </VStack>
            </HStack>
          )}

          <HStack alignItems="center" space="2">
            <Icon mx="1" as={MaterialCommunityIcons} name="map-marker-radius" size="6" />
            {asset.status === ApiV2AssetStatus.Private ? (
              <VStack flex={1}>
                <Text>Location is Private</Text>
              </VStack>
            ) : (
              <VStack flex={1}>
                {!!asset.zone && <Heading fontSize="md">{asset.zone}</Heading>}
                <Text>{asset.address}</Text>
              </VStack>
            )}
            {Platform.OS !== "web" && (
              <Button
                startIcon={<Icon as={MaterialCommunityIcons} name="directions" size="6" />}
                onPress={() => {
                  showLocation({
                    latitude: asset.location!.latitude,
                    longitude: asset.location!.longitude,
                    dialogTitle: `Open Directions`,
                    naverCallerName: "com.trackitnow.era",
                    directionsMode: "car",
                  });
                }}
              />
            )}
          </HStack>

          <HStack alignItems="center" space="2">
            <Icon mx="1" as={MaterialCommunityIcons} name="map-marker-distance" size="6" />
            <VStack flex={1}>
              <Heading fontSize="md">
                {asset.distanceToday} {formatting?.distanceUnit}
              </Heading>
              <Text>Travelled Today</Text>
            </VStack>
          </HStack>

          {!!asset.comment && (
            <HStack alignItems="center" space="2">
              <Icon mx="1" as={MaterialCommunityIcons} name="text" size="6" />
              <VStack flex={1}>
                <Heading fontSize="md">Comment</Heading>
                <Text>{asset.comment}</Text>
              </VStack>
            </HStack>
          )}
        </VStack>
      </PanelScrollView>
    </>
  );
}
