import React, { useEffect, useMemo, useRef, useState } from "react";
import { ApiV2Asset, useLocate } from "~/features/locate/api/locate";
import {
  useLocateGroupOption,
  useLocateSelectedAssetOption,
} from "~/common/hooks/useLocateOptions";
import { useNavigate } from "react-router";
import { ApiV2Zone } from "~/features/locate/api/common";
import { getZoneIcon } from "~/features/locate/display/zone";
import { Marker } from "~/components/Maps/MapView";
import { Box, useToken } from "native-base";
import { CacheFixedImage } from "~/components/Images/CacheFixedImage";
import { AssetIcon } from "~/features/locate/display/assetIcons";
import { View, Text } from "react-native";

function ZoneMarker({ zone }: { zone: ApiV2Zone }) {
  const icon = getZoneIcon(zone);
  if (!icon) return null;
  return (
    <Marker
      width={16}
      height={16}
      id={zone.name}
      latitude={zone.latitude}
      longitude={zone.longitude}
      zIndex={4}
      anchor={{ x: 0.5, y: 1 }}
      title={zone.name}
    >
      <Box zIndex={4}>
        <CacheFixedImage
          alt={zone.name}
          source={icon.source}
          width="4"
          height="4"
          style={{
            transform: [{ rotate: `${icon.angle}deg` }],
          }}
        />
      </Box>
    </Marker>
  );
}

function AssetMarker({ asset, onPress }: { asset: ApiV2Asset; onPress: () => void }) {
  const selectedAsset = useLocateSelectedAssetOption();
  const isSelected = selectedAsset === asset.id;
  const WIDTH = 92;
  const HEIGHT = 28;
  const ICON_SIZE = 16;
  const showLabels = true;
  const selectedLabelColor = useToken("colors", "primary.500");
  return useMemo(
    () => (
      <Marker
        width={92}
        height={28}
        id={asset.id.toString()}
        latitude={asset.location!.latitude}
        longitude={asset.location!.longitude}
        zIndex={isSelected ? 10 : 5}
        anchor={{ x: ICON_SIZE / 2 / WIDTH, y: (HEIGHT - ICON_SIZE / 2) / HEIGHT }}
        onPress={onPress}
      >
        <View
          style={{
            height: 28,
            flexDirection: "row-reverse",
            zIndex: isSelected ? 10 : 5,
          }}
        >
          {showLabels ? (
            <View
              style={{
                marginLeft: -4,
                alignSelf: "flex-start",
                backgroundColor: isSelected ? selectedLabelColor : "#bbb7b5",
                borderRadius: 4,
              }}
            >
              <Text
                style={{
                  color: "black",
                  width: 80,
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                numberOfLines={1}
              >
                {asset.name}
              </Text>
            </View>
          ) : (
            <View
              style={{
                width: 76,
              }}
            />
          )}
          <AssetIcon style={{ alignSelf: "flex-end" }} size={4} asset={asset} />
        </View>
      </Marker>
    ),
    [asset, isSelected]
  );
}

export function MapLocateItems() {
  const selectedAssetRef = useRef<number | undefined>(undefined);
  const selectedAsset = useLocateSelectedAssetOption();
  const group = useLocateGroupOption();
  const { query, assets } = useLocate();
  const navigate = useNavigate();
  const [showMarkers, setShowMarkers] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowMarkers(true);
    });
  }, []);
  useEffect(() => {
    selectedAssetRef.current = selectedAsset;
  }, [selectedAsset]);
  return useMemo(
    () =>
      showMarkers ? (
        <>
          {query.data?.zones.map((zone) => (
            <ZoneMarker zone={zone} key={`zone-${zone.id}`} />
          ))}
          {assets
            .filter((asset) => asset.location !== null)
            .map((asset) => (
              <AssetMarker
                key={`asset-${asset.id}`}
                asset={asset}
                onPress={() => {
                  navigate(`/locate/${group}/asset/${asset.id}`, {
                    replace: selectedAssetRef.current !== undefined,
                  });
                }}
              />
            ))}
        </>
      ) : null,
    [showMarkers, query.dataUpdatedAt, group]
  );
}
