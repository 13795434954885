import { createEndpoint } from "~/common/api/endpoint";
import { z } from "zod";
import { ApiV2Formatting, ApiV2Point, ApiV2Zone } from "./common";
import dayjs from "dayjs";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { useAPIQuery } from "~/common/api/useAPIQuery";
import { useMemo } from "react";
import { speedBracketsString } from "~/features/locate/display/speedBrackets";

export enum ApiV2TrackEntryType {
  Driving = "driving",
  PTO = "pto_on",
  Idle = "idle",
  Stopped = "stopped",
}

export interface ApiV2TrackSegment {
  speed: string;
  private: boolean;
  points: ApiV2Point[];
  entryId: string;
}

export interface ApiV2TrackOverviewItem {
  name: string;
  duration: number;
  cost: number | null;
  distance: number | null;
}

export interface ApiV2TrackEntry extends ApiV2Point {
  id: string;
  type: ApiV2TrackEntryType;
  number: number;
  duration: number;
  distance: number | null;
  location: string;
  from: number | null;
  to: number | null;
}

export interface ApiV2TrackResponse {
  zones: ApiV2Zone[];
  segments: ApiV2TrackSegment[];
  entries: ApiV2TrackEntry[];
  overview: ApiV2TrackOverviewItem[];
  objectId: number;
  formatting: ApiV2Formatting;
}

export const trackEndpoint = createEndpoint()
  .withPath("track/v2")
  .withMethod("GET")
  .withParams(
    z.object({
      objectId: z.number(),
      startTimeUtc: z.number(),
      finishTimeUtc: z.number(),
      speedBrackets: z.string(),
    })
  )
  .withParamsMutator((data) => {
    return {
      ...data,
      finishTimeUtc: Math.min(Date.now(), data.finishTimeUtc),
    };
  })
  .withResponse<ApiV2TrackResponse>()
  .withResponseMutator((response, params) => ({
    ...response,
    shouldShowRefresh:
      dayjs(params.finishTimeUtc).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD"),
    params,
  }));

export function useTrack() {
  const { selectedAsset, track } = useLocateOptions();
  const query = useAPIQuery(
    trackEndpoint,
    {
      objectId: selectedAsset!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      startTimeUtc: track?.start.valueOf()!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      finishTimeUtc: track?.end.valueOf()!,
      speedBrackets: speedBracketsString,
    },
    {
      refetchInterval: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      keepPreviousData: false,
      enabled: !!(selectedAsset && track),
    }
  );
  const entries = useMemo(() => {
    return query.data?.entries ?? [];
  }, [query.data]);
  return {
    query,
    entries,
  };
}
