import { ImageRequireSource } from "react-native";
import { ApiV2Asset } from "../api/locate";
import { ApiV2AssetStatus, ApiV2AssetType } from "~/features/locate/api/common";

export function getDirection(angle: number) {
  const uniformAngle = ((angle % 360) + 382.5) % 360;
  const direction = Math.floor(uniformAngle / 45);
  return ["N", "NE", "E", "SE", "S", "SW", "W", "NW"][direction];
}

export interface AssetIcon {
  source: ImageRequireSource;
  angle: number;
}

export function getStatusText(asset: Pick<ApiV2Asset, "status" | "type">) {
  switch (asset.status) {
    case ApiV2AssetStatus.Driving:
      if (asset.type === ApiV2AssetType.Plant) return "Working";
      return "Driving";
    case ApiV2AssetStatus.DrivingUnsure:
      if (asset.type === ApiV2AssetType.Plant)
        return "Working (precise location uncertain - poor network coverage)";
      return "Driving (precise location uncertain - poor network coverage)";
    case ApiV2AssetStatus.MovingIgnitionOff:
      return "Moving with ignition off";
    case ApiV2AssetStatus.Stopped:
      return "Stopped";
    case ApiV2AssetStatus.StoppedIdle:
      return "Idle";
    case ApiV2AssetStatus.StoppedIdleUnsure:
      return "Idle (uncertain - poor network coverage)";
    case ApiV2AssetStatus.Private:
      return "Private";
    case ApiV2AssetStatus.NotInstalled:
    case ApiV2AssetStatus.Uninstalled:
      return "Uninstalled";
    default:
      return "Unknown";
  }
}
