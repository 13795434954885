import { IPressableProps, Pressable } from "native-base";

import { useNavigate } from "react-router";
export { BrowserRouter as Router, Routes as Routes, Navigate as Navigate } from "react-router-dom";

type LinkProps = (
  | {
      to: string;
      back?: undefined;
    }
  | {
      back: boolean;
      to?: undefined;
    }
) &
  IPressableProps;
export function Link({ to, back, ...props }: LinkProps) {
  const navigate = useNavigate();
  return (
    <Pressable
      {...props}
      // @ts-expect-error href not in typings
      href={to}
      onPress={(ev) => {
        ev.preventDefault();
        props.onPress?.(ev);
        back ? navigate(-1) : navigate(to || "");
      }}
    />
  );
}
