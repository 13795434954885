import React, { useMemo } from "react";
import { ApiV2TrackEntry, ApiV2TrackSegment, useTrack } from "~/features/locate/api/track";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { Marker, Polyline } from "~/components/Maps/MapView";
import { AssetIcon } from "~/features/locate/display/assetIcons";
import { ApiV2AssetStatus, ApiV2AssetType } from "~/features/locate/api/common";
import { useSpeedBracket } from "~/features/locate/display/speedBrackets";
import Color from "color";

function TrackEntryMarker({ entry }: { entry: ApiV2TrackEntry }) {
  const { selectedTrack, setSelectedTrack } = useLocateOptions();
  const selected = selectedTrack === entry.id;
  return useMemo(() => {
    if (!entry.latitude || !entry.longitude) {
      return null;
    }
    return (
      <Marker
        width={selected ? 32 : 24}
        height={selected ? 32 : 24}
        id={entry.id}
        latitude={entry.latitude}
        longitude={entry.longitude}
        zIndex={selected ? 15 : 4}
        anchor={{ x: 0.5, y: 0.5 }}
        title={entry.location}
        onPress={() => {
          setSelectedTrack(entry.id);
        }}
      >
        <AssetIcon
          asset={{
            status:
              {
                driving: ApiV2AssetStatus.Driving,
                stopped: ApiV2AssetStatus.Stopped,
                idle: ApiV2AssetStatus.StoppedIdle,
                pto_on: ApiV2AssetStatus.StoppedIdle,
              }[entry.type] ?? ApiV2AssetStatus.Unknown,
            type: ApiV2AssetType.Car,
            heading: 0,
            speed: 40,
          }}
          selected={selected}
          size={selected ? 8 : 6}
          style={{
            opacity: entry.number ? 1 : 0,
          }}
          label={entry.number?.toString()}
        />
      </Marker>
    );
  }, [entry, selected]);
}

function TrackSegment({ segment, index }: { segment: ApiV2TrackSegment; index: number }) {
  const bracket = useSpeedBracket(segment.speed);
  const { selectedTrack, setSelectedTrack } = useLocateOptions();
  const selected = selectedTrack === segment.entryId;
  return useMemo(
    () => (
      <Polyline
        id={`${segment.entryId}-${index}`}
        onPress={() => {
          setSelectedTrack(segment.entryId);
        }}
        zIndex={selected ? 10 : 0}
        coordinates={segment.points.map((i) => [i.latitude, i.longitude])}
        width={selected ? 12 : 8}
        color={Color(bracket.color)
          .alpha(selected ? 0.8 : 0.5)
          .hexa()}
      />
    ),
    [selected, segment.entryId, index]
  );
}

export function MapTrackItems() {
  const { query } = useTrack();
  return (
    <>
      {query.data?.segments.map((segment, n) => (
        <TrackSegment key={`segment-${n}`} index={n} segment={segment} />
      ))}

      {query.data?.entries
        .filter((i) => i.number)
        .map((entry) => (
          <TrackEntryMarker entry={entry} key={`track-${entry.id}`} />
        ))}
    </>
  );
}
