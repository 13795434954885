import { useMatch } from "react-router";
import dayjs, { Dayjs } from "dayjs";
import { useMemo } from "react";
import { atom, useAtom } from "jotai";

export interface LocateOptions {
  group: "all" | number;
  selectedAsset?: number;
  track?: {
    start: Dayjs;
    end: Dayjs;
  };
  selectedTrack?: string;

  setSelectedTrack(value: string | undefined): void;
}

const selectedTrackAtom = atom<string | null>(null);

export function useLocateGroupOption() {
  const mainParams = useMatch("/locate/:groupId/*")?.params;
  return mainParams?.groupId && mainParams?.groupId !== "all" ? Number(mainParams.groupId) : "all";
}

export function useLocateSelectedAssetOption() {
  const assetParams = useMatch("/locate/:groupId/asset/:id/*")?.params;
  return assetParams?.id ? Number(assetParams.id) : undefined;
}

export function useLocateTrackOption() {
  const trackParams = useMatch("/locate/:groupId/asset/:id/track/:start/:end")?.params;
  return useMemo(() => {
    if (!trackParams) {
      return undefined;
    }
    return {
      start: dayjs(trackParams.start).startOf("day"),
      end: dayjs(trackParams.end).endOf("day"),
    };
  }, [trackParams?.start, trackParams?.end]);
}

export function useLocateSelectedTrackOption() {
  const [selectedTrack, setSelectedTrack] = useAtom(selectedTrackAtom);
  return {
    selectedTrack: selectedTrack ?? undefined,
    setSelectedTrack(value: string | undefined) {
      setSelectedTrack(value ?? null);
    },
  };
}

export function useLocateOptions(): LocateOptions {
  const { selectedTrack, setSelectedTrack } = useLocateSelectedTrackOption();
  const group = useLocateGroupOption();
  const selectedAsset = useLocateSelectedAssetOption();
  const track = useLocateTrackOption();

  return {
    group,
    selectedAsset,
    track,
    selectedTrack,
    setSelectedTrack,
  };
}
