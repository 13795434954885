import { Heading, HStack, Icon, IconButton, ScrollView, VStack } from "native-base";
import React from "react";
import { useNavigate } from "react-router";
import { allChangelogs } from "~/changelogs";
import { Changelog } from "~/features/changelogs/modal";
import { MaterialCommunityIcons } from "@expo/vector-icons";

export function ChangelogHistoryScreen() {
  const navigate = useNavigate();
  return (
    <ScrollView
      bg="primary.100"
      _dark={{ bg: "dark.100" }}
      style={{ flex: 1 }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <VStack
        width="500px"
        maxWidth="100%"
        alignSelf="center"
        space="2"
        safeArea
        p="2"
        flexGrow="1"
      >
        <HStack alignItems="center">
          <IconButton
            onPress={() => navigate(-1)}
            icon={<Icon as={MaterialCommunityIcons} name="chevron-left" m={-1} size="xl" />}
          />
          <Heading>Changelog</Heading>
        </HStack>

        {allChangelogs.slice().map((changelog) => (
          <Changelog key={changelog.version} changelog={changelog} />
        ))}
      </VStack>
    </ScrollView>
  );
}
