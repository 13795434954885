import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import {
  useBottomSheetInternal,
  BottomSheetFlatList,
  BottomSheetScrollView,
  ANIMATION_SOURCE,
} from "@gorhom/bottom-sheet";
import { FlatList, FlatListProps, ScrollView, useWindowDimensions } from "react-native";
import { Box, Pressable } from "native-base";

const SafeAreaAwareBottomSheetCompatibleScrollView = forwardRef(function (
  {
    children,
    enableSafeArea,
  }: {
    children: ReactNode;
    enableSafeArea: boolean;
  },
  ref: ForwardedRef<ScrollView>
) {
  const isInBottomSheet = !!useBottomSheetInternal(true);
  const ScrollViewComponent = isInBottomSheet ? BottomSheetScrollView : ScrollView;
  return (
    <ScrollViewComponent ref={ref} style={{ flex: 1 }}>
      <Box safeAreaBottom={enableSafeArea} style={{ flexGrow: 1 }}>
        {children}
      </Box>
    </ScrollViewComponent>
  );
});

export function PanelScrollView({
  header,
  ...props
}: {
  children: ReactNode;
} & { header: ReactNode }) {
  const scrollRef = React.useRef<ScrollView>(null);
  const windowSize = useWindowDimensions();
  const isDesktop = windowSize.width >= 800;
  const bottomSheet = useBottomSheetInternal(true);
  return (
    <Box flex={1}>
      <Pressable
        onPress={() => {
          scrollRef.current?.scrollTo({ animated: true, y: 0 });
          if (bottomSheet?.animatedIndex.value === 0)
            bottomSheet?.animateToPosition(
              bottomSheet?.animatedSnapPoints.value[1],
              ANIMATION_SOURCE.USER
            );
        }}
      >
        {header}
      </Pressable>
      <SafeAreaAwareBottomSheetCompatibleScrollView
        enableSafeArea={!isDesktop}
        ref={scrollRef}
        children={props.children}
      />
    </Box>
  );
}

const SafeAreaAwareBottomSheetCompatibleFlatList = forwardRef(function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: FlatListProps<any> & { enableSafeArea: boolean },
  ref: ForwardedRef<FlatList>
) {
  const isInBottomSheet = !!useBottomSheetInternal(true);
  const FlatListComponent = isInBottomSheet ? BottomSheetFlatList : FlatList;
  return (
    <FlatListComponent
      // @ts-expect-error Typings aren't quite correct, but ref is only used in PanelFlatList so not too dangerous.
      ref={ref}
      {...props}
      ListFooterComponent={
        <Box safeAreaBottom={props.enableSafeArea} style={{ flexGrow: 1 }}>
          {props.ListFooterComponent}
        </Box>
      }
    />
  );
});

export function PanelFlatList<T>({ header, ...props }: FlatListProps<T> & { header: ReactNode }) {
  const scrollRef = React.useRef<FlatList>(null);
  const windowSize = useWindowDimensions();
  const isDesktop = windowSize.width >= 800;
  const bottomSheet = useBottomSheetInternal(true);
  return (
    <Box flex={1}>
      <Pressable
        onPress={() => {
          scrollRef.current?.scrollToOffset({ animated: true, offset: 0 });
          if (bottomSheet?.animatedIndex.value === 0)
            bottomSheet?.animateToPosition(
              bottomSheet?.animatedSnapPoints.value[1],
              ANIMATION_SOURCE.USER
            );
        }}
      >
        {header}
      </Pressable>
      <SafeAreaAwareBottomSheetCompatibleFlatList
        enableSafeArea={!isDesktop}
        ref={scrollRef}
        {...props}
      />
    </Box>
  );
}
