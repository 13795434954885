import { Heading, HStack, Icon, IconButton, Text, VStack } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { useNavigate } from "react-router";

export interface PanelHeaderProps {
  back?: boolean;
  upperTitle?: string;
  title: string;
  titleRight?: string;
  subtitle?: string;
  button?: React.ReactNode;
}

export function PanelHeader(props: PanelHeaderProps) {
  const navigate = useNavigate();
  return (
    <HStack h="75px" p="2" alignItems="center">
      {props.back && (
        <IconButton
          onPress={() => navigate(-1)}
          icon={<Icon as={MaterialCommunityIcons} name="chevron-left" m={-2} size="3xl" />}
        />
      )}
      <VStack flex={1}>
        {!!props.upperTitle && (
          <Text fontWeight="bold" fontSize="xs">
            {props.upperTitle}
          </Text>
        )}
        <HStack>
          <Heading numberOfLines={1} ellipsizeMode="tail" flexShrink={1} fontSize="lg">
            {props.title.trimEnd()}&nbsp;
          </Heading>
          {!!props.titleRight && (
            <Heading flexShrink={0} numberOfLines={1} fontSize="lg">
              {props.titleRight}
            </Heading>
          )}
        </HStack>
        {!!props.subtitle && <Text fontSize="xs">{props.subtitle}</Text>}
      </VStack>
      {props.button}
    </HStack>
  );
}
