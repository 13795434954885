import { Endpoint, mutatedResponseSymbol } from "~/common/api/endpoint";
import { z } from "zod";

export async function apiFetch<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  E extends Endpoint<any, any>
>(
  basePath: string,
  endpoint: E,
  data: z.infer<E["params"]>,
  login: { username: string; token: string } | undefined,
  onAuthenticationFailure: () => void
): Promise<E[typeof mutatedResponseSymbol]> {
  let path = `${basePath}${endpoint.path}`;
  const request = {
    method: endpoint.method,
    headers: new Headers(),
    body: undefined as string | undefined,
  };
  let mutatedData = data;
  if (endpoint.paramsMutator) {
    mutatedData = endpoint.paramsMutator(data);
  }
  if (login?.token) {
    request.headers?.append("Authorization", `Bearer ${login.token}`);
  }
  if (request.method === "POST") {
    request.headers.append("Content-Type", "application/json");
    if (data) request.body = JSON.stringify(mutatedData);
  } else {
    if (data)
      path += `?${new URLSearchParams(
        Object.fromEntries(Object.entries(mutatedData).filter((i) => i[1])) as Record<
          string,
          string
        >
      ).toString()}`;
  }
  let response;
  try {
    response = await fetch(path, request);
  } catch {
    throw {
      Message: "Failed to connect to the server",
    };
  }
  if (response.ok) {
    const data = await response.json();
    if (endpoint.responseMutator) {
      return endpoint.responseMutator(data, mutatedData);
    }
    return data;
  }
  if (response.status === 401) {
    onAuthenticationFailure();
  }
  throw await response.json();
}
