import { Button, Popover, VStack } from "native-base";
import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import dayjs from "dayjs";
import { DateRangePickerModal } from "~/components/DatePicker/DateRangePickerModal";
import { ANIMATION_SOURCE, useBottomSheetInternal } from "@gorhom/bottom-sheet";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TrackDatePickerPopover(props: { trigger: (triggerProps: any) => ReactElement }) {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { group, selectedAsset, track } = useLocateOptions();
  const bottomSheet = useBottomSheetInternal(true);

  return (
    <>
      <Popover
        isOpen={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={props.trigger}
      >
        <Popover.Content w="56">
          <Popover.Arrow />
          <Popover.Body>
            <VStack space="2">
              <Button
                onPress={() => {
                  setOpen(false);
                  if (!track)
                    bottomSheet?.animateToPosition(
                      bottomSheet?.animatedSnapPoints.value[1],
                      ANIMATION_SOURCE.USER
                    );
                  navigate(
                    `/locate/${group}/asset/${selectedAsset}/track/${dayjs()
                      .startOf("day")
                      .format("YYYY-MM-DD")}/${dayjs().endOf("day").format("YYYY-MM-DD")}`,
                    { replace: !!track }
                  );
                }}
              >
                Today
              </Button>
              <Button
                onPress={() => {
                  setOpen(false);
                  if (!track)
                    bottomSheet?.animateToPosition(
                      bottomSheet?.animatedSnapPoints.value[1],
                      ANIMATION_SOURCE.USER
                    );
                  navigate(
                    `/locate/${group}/asset/${selectedAsset}/track/${dayjs()
                      .add(-1, "day")
                      .startOf("day")
                      .format("YYYY-MM-DD")}/${dayjs()
                      .add(-1, "day")
                      .endOf("day")
                      .format("YYYY-MM-DD")}`,
                    { replace: !!track }
                  );
                }}
              >
                Yesterday
              </Button>
              <Button
                onPress={() => {
                  setModalOpen(true);
                  setOpen(false);
                }}
              >
                Custom
              </Button>
            </VStack>
          </Popover.Body>
        </Popover.Content>
      </Popover>
      <DateRangePickerModal
        key={`${track?.start}-${track?.end}`}
        initialStartDate={track?.start.toDate()}
        initialEndDate={track?.end.toDate()}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onSelect={(start, end) => {
          setModalOpen(false);
          if (!track)
            bottomSheet?.animateToPosition(
              bottomSheet?.animatedSnapPoints.value[1],
              ANIMATION_SOURCE.USER
            );
          navigate(
            `/locate/${group}/asset/${selectedAsset}/track/${dayjs(start)
              .startOf("day")
              .format("YYYY-MM-DD")}/${dayjs(end).endOf("day").format("YYYY-MM-DD")}`,
            { replace: !!track }
          );
        }}
      />
    </>
  );
}
