import { BlurView } from "expo-blur";
import React, { ReactNode } from "react";
import { useColorMode, useToken } from "native-base";
import { View, ViewStyle } from "react-native";
import Animated, {
  BaseAnimationBuilder,
  EntryExitAnimationFunction,
  Keyframe,
  LayoutAnimationFunction,
} from "react-native-reanimated";

const AnimatedBlurView = Animated.createAnimatedComponent(BlurView);

export const BlurredBox: React.FC<{
  status?: "danger";
  children: ReactNode;
  style: ViewStyle;
  layout?: BaseAnimationBuilder | LayoutAnimationFunction | typeof BaseAnimationBuilder;
  entering?:
    | BaseAnimationBuilder
    | typeof BaseAnimationBuilder
    | EntryExitAnimationFunction
    | Keyframe;
  exiting?:
    | BaseAnimationBuilder
    | typeof BaseAnimationBuilder
    | EntryExitAnimationFunction
    | Keyframe;
}> = (props) => {
  const { colorMode } = useColorMode();
  const lightGreen = useToken("colors", props.status === "danger" ? "red.200" : "primary.200");
  const darkGreen = useToken("colors", props.status === "danger" ? "red.800" : "dark.100");
  const shadow = useToken("shadows", "2");

  return (
    <AnimatedBlurView
      entering={props.entering}
      exiting={props.exiting}
      layout={props.layout}
      intensity={50}
      tint={colorMode === "dark" ? "dark" : "light"}
      style={[
        props.style,
        {
          borderRadius: 8,
          overflow: "hidden",
          ...shadow,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: colorMode === "dark" ? darkGreen + "aa" : lightGreen + "aa",
          borderRadius: 8,
        }}
      >
        {props.children}
      </View>
    </AnimatedBlurView>
  );
};
