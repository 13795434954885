import { Icon, useToken } from "native-base";
import { ApiV2Asset } from "../api/locate";
import { getSpeedBracket, SpeedBracket, useSpeedBrackets } from "./speedBrackets";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { ApiV2AssetStatus, ApiV2AssetType } from "~/features/locate/api/common";
import { useAtomValue } from "jotai";
import { disableAssetIconsAtom } from "~/features/settings/debugging/screen";
import { View, ViewStyle, Text } from "react-native";

export function getAssetIcon(
  speedBrackets: SpeedBracket[],
  asset: Pick<ApiV2Asset, "status" | "type" | "speed">
) {
  const speedBracket = getSpeedBracket(speedBrackets, asset.speed);
  let color = "gray.500";
  let hasDirection = false;
  let name = "Unknown";
  let showOnLegend = true;
  let isUnsure = false;
  let isPrivate = false;
  switch (asset.status) {
    case ApiV2AssetStatus.Private:
      color = "purple.500";
      name = "Private";
      showOnLegend = false;
      isPrivate = true;
      break;
    case ApiV2AssetStatus.Stopped:
      color = "black";
      name = "Stopped";
      break;
    case ApiV2AssetStatus.StoppedIdle:
      color = "red.500";
      name = "Idling";
      break;
    case ApiV2AssetStatus.StoppedIdleUnsure:
      color = "red.500";
      name = "Idling Unsure";
      isUnsure = true;
      break;
    case ApiV2AssetStatus.DrivingUnsure:
      if (asset.type === ApiV2AssetType.Plant) {
        color = "green.500";
        isUnsure = true;
        name = "Working Unsure";
        break;
      }
      hasDirection = true;
      color = "gray.400";
      name = "Driving Unsure";
      break;
    case ApiV2AssetStatus.Driving:
      if (asset.type === ApiV2AssetType.Plant) {
        color = "green.500";
        name = "Working";
        break;
      }
      hasDirection = true;
      showOnLegend = false;
      color = speedBracket?.color ?? "gray.500";
      name = speedBracket?.label ?? "Driving";
      break;
  }
  return { color, hasDirection, isUnsure, isPrivate, showOnLegend, name };
}

export function AssetIcon({
  asset,
  size = 6,
  label,
  selected,
  style,
}: {
  asset: Pick<ApiV2Asset, "status" | "type" | "speed" | "heading">;
  size?: number;
  label?: string;
  selected?: boolean;
  style?: ViewStyle;
}) {
  const speedBrackets = useSpeedBrackets();

  const disableAssetIcons = useAtomValue(disableAssetIconsAtom);

  if (disableAssetIcons) return null;

  const {
    color: colorName,
    hasDirection,
    isUnsure,
    isPrivate,
  } = getAssetIcon(speedBrackets, asset);

  const borderColor = useToken("colors", selected ? "primary.500" : "black");
  const gray300 = useToken("colors", "gray.300");
  const color = useToken("colors", colorName) ?? colorName;

  return (
    <View
      style={[
        {
          height: size * 4,
          width: size * 4,
          alignItems: "center",
          justifyContent: "center",
        },
        style,
      ]}
    >
      <View
        style={{
          borderRadius: 100000,
          height: 24,
          width: 24,
          backgroundColor: hasDirection ? borderColor : "white",
          transform: [
            {
              scale: size / 6,
            },
          ],
          padding: isPrivate || hasDirection ? 0 : label ? 2 : 3,
          borderWidth: 2,
          borderColor: borderColor,
        }}
      >
        <View
          style={{
            borderRadius: 100000,
            alignSelf: "stretch",
            flex: 1,
            backgroundColor: isPrivate ? "transparent" : color,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            transform: hasDirection
              ? [
                  {
                    rotate: `${asset.heading}deg`,
                  },
                ]
              : [],
          }}
        >
          {isUnsure && (
            <View
              style={{
                backgroundColor: gray300,
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                width: "45%",
              }}
            />
          )}
          {label ? (
            <Text
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: 12,
                lineHeight: 12,
              }}
            >
              {label}
            </Text>
          ) : hasDirection ? (
            <Icon as={MaterialCommunityIcons} name="navigation" size="4" color="black" />
          ) : null}
          {isPrivate && (
            <Text style={{ color, fontWeight: "bold", fontSize: 16, lineHeight: 16 }}>P</Text>
          )}
        </View>
      </View>
    </View>
  );
}
