import { useAtomValue } from "jotai";
import { Route, useNavigate } from "react-router";
import { loginAtom } from "~/common/storage/atoms";
import { LoginScreen } from "~/features/auth/login/screen";
import { ForgotPasswordScreen } from "~/features/auth/forgotPassword/screen";
import { Map } from "~/features/locate/map";
import { Navigate, Routes } from "~/features/navigation/router";
import { SettingsScreen } from "~/features/settings/screen";
import { DebuggingScreen } from "~/features/settings/debugging/screen";
import { ListPanel } from "~/features/locate/panels/listPanel";
import { GroupsPanel } from "~/features/locate/panels/groupsPanel";
import { DetailsPanel } from "~/features/locate/panels/detailsPanel";
import { TrackPanel } from "~/features/locate/panels/trackPanel";
import { useEffect } from "react";
import { TabbedScreen } from "~/features/navigation/tabbedScreen";
import { BackHandler } from "react-native";
import { ChangelogHistoryScreen } from "~/features/changelogs/screen";
import { Panels } from "~/components/Panels/Panels";

export function Navigation() {
  const navigate = useNavigate();
  useEffect(() => {
    const backAction = () => {
      navigate(-1);
      return true;
    };

    const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);

    return () => backHandler.remove();
  }, []);

  const login = useAtomValue(loginAtom);
  if (!login) {
    return (
      <Routes>
        <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route element={<TabbedScreen />}>
        <Route path="/settings" element={<SettingsScreen />} />
        <Route path="/settings/debugging" element={<DebuggingScreen />} />
        <Route path="/settings/changelogs" element={<ChangelogHistoryScreen />} />
        <Route path="*" element={<Navigate to="/locate/all" replace />} />
        <Route element={<Map />}>
          <Route
            path="/locate/:groupId"
            element={<Panels leftKey="AssetsList" left={<ListPanel />} />}
          />
          <Route
            path="/locate/:groupId/groups"
            element={<Panels leftKey="AssetsGroups" left={<GroupsPanel />} />}
          />
          <Route
            path="/locate/:groupId/asset/:id"
            element={
              <Panels
                leftKey="AssetsList"
                left={<ListPanel />}
                rightKey="AssetDetails"
                right={<DetailsPanel />}
              />
            }
          />
          <Route
            path="/locate/:groupId/asset/:id/track/:start/:end"
            element={
              <Panels
                leftKey="AssetsList"
                left={<ListPanel />}
                rightKey="AssetDetails"
                right={<TrackPanel />}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}
