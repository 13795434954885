import { useEffect } from "react";
import { changelogVersionAtom } from "~/common/storage/atoms";
import { useAtom } from "jotai";
import { Button, Heading, HStack, Icon, Modal, Text, VStack } from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { CacheFixedImage } from "~/components/Images/CacheFixedImage";
import { ChangelogInterface, allChangelogs } from "~/changelogs";

export function Changelog({ changelog }: { changelog: ChangelogInterface }) {
  return (
    <HStack alignItems="center" p="2" space="2" key={changelog.version}>
      {changelog.icon && (
        <Icon
          as={MaterialCommunityIcons}
          color={changelog.iconColor}
          name={changelog.icon}
          size={8}
        />
      )}
      {changelog.image && (
        <CacheFixedImage
          source={changelog.image}
          h="8"
          w="8"
          rounded={changelog.imageRounded ? "full" : 0}
        />
      )}
      <VStack flex={1}>
        <Heading size="md">{changelog.title}</Heading>
        <Text>{changelog.description}</Text>
      </VStack>
    </HStack>
  );
}

export function ChangelogsModal() {
  const latestChangelogVersion = allChangelogs[0].version;

  const [changelogVersion, setChangelogVersion] = useAtom(changelogVersionAtom);
  useEffect(() => {
    if (changelogVersion === undefined || changelogVersion === null) {
      setChangelogVersion(latestChangelogVersion);
    }
    if ((changelogVersion ?? latestChangelogVersion) > latestChangelogVersion) {
      setChangelogVersion(latestChangelogVersion);
    }
  }, [changelogVersion]);

  const changelogOpen = (changelogVersion ?? latestChangelogVersion) < latestChangelogVersion;

  // Only show the last 10 unseen changelogs
  const changelogs = allChangelogs
    .filter((changelog) => changelog.version > (changelogVersion ?? latestChangelogVersion))
    .slice(-10);

  return (
    <Modal
      size="lg"
      isOpen={changelogOpen && changelogs.length > 0}
      onClose={() => setChangelogVersion(latestChangelogVersion)}
    >
      <Modal.Content maxWidth="600px">
        <Modal.CloseButton />
        <Modal.Header>What's New</Modal.Header>
        <Modal.Body>
          <VStack>
            {changelogs.map((changelog) => (
              <Changelog changelog={changelog} />
            ))}
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onPress={() => {
              setChangelogVersion(latestChangelogVersion);
            }}
          >
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
