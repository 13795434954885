export interface ApiV2Formatting {
  distanceUnit: string;
  speedUnit: string;
  /**
   * @deprecated Use app-side useSpeedBrackets() instead
   */
  speedBrackets: ApiV2SpeedBracket[];
}

export interface ApiV2SpeedBracket {
  name: string;
  color: string;
}

export enum ApiV2ZoneDisplay {
  Hidden = "Hidden",
  Outline = "Outline",
  PoiBlue = "PoiBlue",
  PoiGreen = "PoiGreen",
  PoiRed = "PoiRed",
  PoiYellow = "PoiYellow",
}

export interface ApiV2Zone {
  id: number;
  name: string;
  display: ApiV2ZoneDisplay;
  latitude: number;
  longitude: number;
}

export interface ApiV2Point {
  latitude: number;
  longitude: number;
}

export enum ApiV2AssetStatus {
  NotInstalled = "Not installed",
  Unknown = "Unknown",
  Stopped = "Stopped",
  StoppedIdle = "StoppedIdle",
  Driving = "Driving",
  MovingIgnitionOff = "MovingIgnitionOff",
  StoppedIdleUnsure = "StoppedIdleUnsure",
  DrivingUnsure = "DrivingUnsure",
  Private = "Private",
  Uninstalled = "Uninstalled",
}

export enum ApiV2AssetType {
  Car = "Car",
  // [Description("HGV (artic.)")
  HGVartic = "HGVartic",
  Plant = "Plant",
  Aviation = "Aviation",
  Unknown = "Unknown",
  Motorbike = "Motorbike",
  Van = "Van",
  // [Description("HGV (rigid)")
  HGVrigid = "HGVrigid",
  Coach = "Coach",
  Marine = "Marine",
  Trailer = "Trailer",
  // [Description("Walking / Sports")
  WalkingSports = "WalkingSports",
}
