import { MaterialCommunityIcons } from "@expo/vector-icons";
import { VStack, Heading, Text, HStack, Button, Icon, Box, Pressable, Divider } from "native-base";
import React, { useMemo, useRef } from "react";
import dayjs from "dayjs";
import {
  PanelFlatList,
  PanelScrollView,
} from "~/components/BottomSheet/BottomSheetCompatibleViews";
import { useLocate } from "~/features/locate/api/locate";
import { useTrack } from "~/features/locate/api/track";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { ApiV2Asset } from "~/features/locate/api/locate";
import { ApiV2TrackEntry } from "~/features/locate/api/track";
import { TrackDatePickerPopover } from "~/components/DatePicker/TrackDatePickerPopover";
import { PanelHeader } from "~/components/BottomSheet/PanelHeader";
import { BlurredBox } from "~/components/Blurred/BlurredBox";
import { AssetIcon } from "../display/assetIcons";
import { ApiV2AssetStatus, ApiV2AssetType } from "~/features/locate/api/common";

function formatDuration(milliseconds: number) {
  const hours = Math.floor(milliseconds / (1000 * 60 ** 2));
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes} min${minutes === 1 ? "" : "s"}`;
}

function Entry({
  entry,
  onPress,
  index,
  total,
  distanceUnit,
}: {
  entry: ApiV2TrackEntry;
  onPress: () => void;
  index: number;
  total: number;
  distanceUnit: string;
}) {
  const { track: { start } = {}, selectedTrack } = useLocateOptions();
  const duration = formatDuration(entry.duration);
  const hasStartAndEnd = entry.from && entry.to;
  const isMultiDay =
    dayjs(entry.from ?? start)?.format("DD-MM-YYYY") !== dayjs(entry.to)?.format("DD-MM-YYYY");
  return (
    <Pressable onPress={onPress}>
      <HStack
        _dark={{
          bg: selectedTrack === entry.id ? "primary.100:alpha.10" : undefined,
        }}
        bg={selectedTrack === entry.id ? "primary.500:alpha.20" : undefined}
        py="2"
        px="2"
        space="2"
      >
        <VStack alignSelf="stretch">
          {index !== total - 1 && (
            <Box
              width="0.5"
              position="absolute"
              top={0}
              bottom="-16px"
              alignSelf="center"
              bg="primary.500"
            />
          )}
          <AssetIcon
            asset={{
              status:
                {
                  driving: ApiV2AssetStatus.Driving,
                  stopped: ApiV2AssetStatus.Stopped,
                  idle: ApiV2AssetStatus.StoppedIdle,
                  pto_on: ApiV2AssetStatus.StoppedIdle,
                }[entry.type] ?? ApiV2AssetStatus.Unknown,
              type: ApiV2AssetType.Car,
              heading: 0,
              speed: 40,
            }}
            size={6}
            style={{
              opacity: entry.number ? 1 : 0,
            }}
            label={entry.number?.toString()}
          />
        </VStack>
        <VStack py="0.5" flex={1}>
          {entry.location ? (
            <>
              {hasStartAndEnd ? (
                <Text fontSize="sm">
                  <Text fontSize="md" fontWeight="bold">
                    {dayjs(entry.from).format("ddd DD/MM | LT")}
                  </Text>{" "}
                  to{" "}
                  <Text fontSize="md" fontWeight="bold">
                    {dayjs(entry.to).format(isMultiDay ? "ddd DD/MM | LT" : "LT")}
                  </Text>
                </Text>
              ) : (
                <Text fontSize="sm">
                  <Text fontSize="md" fontWeight="bold">
                    {start?.format("ddd DD/MM | LT") ?? "???"}
                  </Text>{" "}
                  (already here) to{" "}
                  <Text fontSize="md" fontWeight="bold">
                    {dayjs(entry.to).format(isMultiDay ? "ddd DD/MM | LT" : "LT")}
                  </Text>
                </Text>
              )}
              <Text>{entry.location}</Text>
            </>
          ) : (
            <>
              <Text>
                Travelled {entry.distance} {distanceUnit}
              </Text>
            </>
          )}
        </VStack>
        {hasStartAndEnd && (
          <VStack>
            <Text textAlign="right">{duration}</Text>
          </VStack>
        )}
      </HStack>
    </Pressable>
  );
}

export function TrackPanel() {
  const { selectedAsset: actualAsset } = useLocate();
  const { query, entries } = useTrack();
  const trackData = query.data;
  const { track, selectedAsset, setSelectedTrack, selectedTrack } = useLocateOptions();

  const previousAsset = useRef<ApiV2Asset | null>(null);
  const asset = useMemo(() => {
    if (!selectedAsset) {
      return actualAsset ?? previousAsset.current;
    }
    previousAsset.current = actualAsset ?? null;
    return actualAsset;
  }, [actualAsset, selectedAsset]);

  if (!asset) {
    return (
      <PanelScrollView header={<PanelHeader back title="Asset not found" />}>
        <VStack p="2" space="2">
          <Text>Please check the URL and try again.</Text>
        </VStack>
      </PanelScrollView>
    );
  }

  const start = dayjs(track?.start).format("ddd DD/MM/YY");
  const end = dayjs(track?.end).format("ddd DD/MM/YY");

  const header = (
    <PanelHeader
      back
      upperTitle="Historic Track for"
      title={asset.name}
      subtitle={`${start}${end !== start ? ` to ${end}` : ""}`}
      button={
        <TrackDatePickerPopover
          trigger={(triggerProps) => (
            <Button
              size="md"
              {...triggerProps}
              startIcon={<Icon as={MaterialCommunityIcons} name="calendar" size="md" />}
            >
              Dates
            </Button>
          )}
        />
      }
    />
  );

  if (query.error && "type" in query.error && query.error.type === "forbidden") {
    return (
      <PanelScrollView header={header}>
        <VStack space="2" p="2">
          <BlurredBox
            status="danger"
            style={{
              width: "100%",
            }}
          >
            <VStack p="2">
              <Heading size="lg">Missing Permission</Heading>
              <Text>{query.error.message}</Text>
            </VStack>
          </BlurredBox>
        </VStack>
      </PanelScrollView>
    );
  }

  if (query.error && query.errorUpdatedAt > query.dataUpdatedAt) {
    return (
      <PanelScrollView header={header}>
        <VStack space="2" p="2">
          <BlurredBox
            status="danger"
            style={{
              width: "100%",
            }}
          >
            <VStack p="2">
              <Heading size="lg">Unable to load data</Heading>
              <Text>Please check your network connection.</Text>
              <Button
                alignSelf="flex-end"
                onPress={() => {
                  query.refetch();
                }}
              >
                Reload
              </Button>
            </VStack>
          </BlurredBox>
        </VStack>
      </PanelScrollView>
    );
  }

  if (!trackData || !track) {
    return (
      <PanelScrollView header={header}>
        <VStack space="2" p="2">
          <Text>Loading...</Text>
        </VStack>
      </PanelScrollView>
    );
  }

  const isEmpty = !trackData.entries.some((i) => i.number);

  return (
    <>
      <PanelFlatList
        header={header}
        keyExtractor={(i) => i.id}
        ListHeaderComponent={
          <VStack space="2" p="2">
            {trackData.shouldShowRefresh && (
              <HStack mt="-2" alignItems="center">
                <Text>Data loaded up to {dayjs(trackData.params.finishTimeUtc).format("LT")}</Text>
                <Button
                  variant="ghost"
                  size="md"
                  py="2"
                  px="2"
                  onPress={() => {
                    query.refetch();
                  }}
                >
                  Reload
                </Button>
              </HStack>
            )}
            {trackData.shouldShowRefresh && (
              <Divider
                bg="primary.500"
                _dark={{
                  bg: "white:alpha.50",
                }}
              />
            )}
            {isEmpty ? (
              <Text>No data available for this period.</Text>
            ) : (
              <>
                <HStack space="2">
                  <VStack flex={1}>
                    <Text h="6" fontWeight="bold">
                      Category
                    </Text>
                    {trackData.overview.map((i) => (
                      <Text fontWeight={i.name === "Total" ? "bold" : "normal"} h="6">
                        {i.name}
                      </Text>
                    ))}
                  </VStack>
                  <VStack flex={1}>
                    <Text textAlign="right" h="6" fontWeight="bold">
                      Duration
                    </Text>
                    {trackData.overview.map((i) => (
                      <Text
                        textAlign="right"
                        fontWeight={i.name === "Total" ? "bold" : "normal"}
                        h="6"
                      >
                        {formatDuration(i.duration)}
                      </Text>
                    ))}
                  </VStack>
                  <VStack flex={1}>
                    <Text textAlign="right" h="6" fontWeight="bold">
                      Cost
                    </Text>
                    {trackData.overview.map((i) => (
                      <Text
                        textAlign="right"
                        fontWeight={i.name === "Total" ? "bold" : "normal"}
                        h="6"
                      >
                        {i.cost ? `£${i.cost.toFixed(2)}` : ""}
                      </Text>
                    ))}
                  </VStack>
                  <VStack flex={1}>
                    <Text textAlign="right" h="6" fontWeight="bold">
                      Distance
                    </Text>
                    {trackData.overview.map((i) => (
                      <Text
                        textAlign="right"
                        fontWeight={i.name === "Total" ? "bold" : "normal"}
                        h="6"
                      >
                        {i.distance ? `${i.distance} ${trackData.formatting.distanceUnit}` : ""}
                      </Text>
                    ))}
                  </VStack>
                </HStack>
              </>
            )}
          </VStack>
        }
        data={isEmpty ? [] : entries ?? []}
        renderItem={({ item, index }) => (
          <Entry
            onPress={() => {
              if (selectedTrack === item.id) {
                setSelectedTrack(undefined);
              } else {
                setSelectedTrack(item.id);
              }
            }}
            entry={item}
            index={index}
            total={(trackData.entries.entries ?? []).length}
            distanceUnit={trackData.formatting.distanceUnit}
          />
        )}
      />
    </>
  );
}
