import { Box, Button, Heading, HStack, Icon, Menu, Switch, Text, VStack } from "native-base";
import { BlurredBox } from "~/components/Blurred/BlurredBox";
import React, { useMemo } from "react";
import { useSpeedBrackets } from "./display/speedBrackets";
import { AssetIcon, getAssetIcon } from "./display/assetIcons";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { useLocate, ApiV2Asset } from "./api/locate";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Platform } from "react-native";
import { atomWithMMKV } from "~/common/storage/atoms";
import { useAtom } from "jotai";
import { ApiV2AssetStatus, ApiV2AssetType } from "~/features/locate/api/common";

export const mapStyleAtom = atomWithMMKV<"standard" | "hybrid" | "terrain">("mapStyle", "standard");
export const mapLegendAtom = atomWithMMKV<boolean>("mapLegend", false);

export interface LayersMenuProps {
  fitToContent(): void;
}

export function LayersMenu(props: LayersMenuProps) {
  const [, setMapStyle] = useAtom(mapStyleAtom);
  const [showLegend, setShowLegend] = useAtom(mapLegendAtom);
  return (
    <Menu
      w="190"
      shouldOverlapWithTrigger={false}
      trigger={(triggerProps) => {
        return (
          <Button
            pointerEvents="auto"
            colorScheme="primary"
            startIcon={<Icon size="md" as={MaterialCommunityIcons} name="layers" />}
            {...triggerProps}
          />
        );
      }}
    >
      <Menu.Item
        onPress={() => {
          setMapStyle("standard");
        }}
      >
        Standard Map
      </Menu.Item>
      <Menu.Item
        onPress={() => {
          setMapStyle("hybrid");
        }}
      >
        Satellite Map
      </Menu.Item>
      {Platform.OS !== "ios" && (
        <Menu.Item
          onPress={() => {
            setMapStyle("terrain");
          }}
        >
          Terrain Map
        </Menu.Item>
      )}
      <Button
        mx="2"
        mt="2"
        key="zoom"
        colorScheme="primary"
        startIcon={<Icon size="md" as={MaterialCommunityIcons} name="fit-to-page" />}
        onPress={props.fitToContent}
      >
        Fit to Content
      </Button>
      <Text fontSize="xs" color="text.400" px="2">
        Tap to reset the map to show selected track/assets.
      </Text>
      <HStack alignItems="center" p="2">
        <Text flex={1}>Show Legend</Text>
        <Switch isChecked={showLegend} onValueChange={setShowLegend} />
      </HStack>
    </Menu>
  );
}

const trackExampleAssets: Pick<ApiV2Asset, "status" | "speed" | "type">[] = [
  { type: ApiV2AssetType.Car, speed: 0, status: ApiV2AssetStatus.Stopped },
  { type: ApiV2AssetType.Car, speed: 0, status: ApiV2AssetStatus.StoppedIdle },
];

export function Legend() {
  const speedBrackets = useSpeedBrackets();
  const { assets } = useLocate();

  const { track } = useLocateOptions();
  const dedupedAssets = useMemo(() => {
    return Object.values(
      Object.fromEntries(
        (track ? trackExampleAssets : assets)
          .filter((i) => i.status !== ApiV2AssetStatus.NotInstalled)
          .map((asset) => {
            const icon = getAssetIcon(speedBrackets, asset);
            return [icon.name, { asset, icon }];
          })
      )
    )
      .filter((i) => i.icon.showOnLegend)
      .sort((a, b) => a.icon.name.localeCompare(b.icon.name));
  }, [assets, track]);
  return (
    <BlurredBox
      style={{
        width: 100,
      }}
    >
      <VStack py="2" space="1">
        <HStack px="2" space="2" alignItems="center" width="100%" key={`speed-mph`}>
          <Text fontSize="xs" fontWeight="bold">
            Speed (MPH)
          </Text>
        </HStack>
        {speedBrackets.map((i) => (
          <HStack px="2" space="2" alignItems="center" width="100%" key={`speed-${i.minSpeed}`}>
            {track ? (
              <Box height="2" width="4" rounded="full" bg={i.color}></Box>
            ) : (
              <AssetIcon
                asset={{
                  type: ApiV2AssetType.Car,
                  status: ApiV2AssetStatus.Driving,
                  speed: i.minSpeed,
                  heading: 0,
                }}
                size={4}
              />
            )}
            <Text fontSize="xs">{i.label}</Text>
          </HStack>
        ))}
        <HStack px="2" space="2" alignItems="center" width="100%" key={`speed-status`}>
          <Text fontSize="xs" fontWeight="bold">
            Status
          </Text>
        </HStack>
        {dedupedAssets.map((i) => (
          <HStack px="2" space="2" alignItems="center" width="100%" key={`speed-${i.icon.name}`}>
            <AssetIcon asset={{ ...i.asset, heading: 0 }} size={4} />
            <Text flex={1} fontSize="xs">
              {i.icon.name}
            </Text>
          </HStack>
        ))}
      </VStack>
    </BlurredBox>
  );
}

export function UnableToLoadDataBanner() {
  const { query } = useLocate();
  if (query.failureCount > 2 || query.errorUpdatedAt > query.dataUpdatedAt)
    return (
      <Box pointerEvents="none">
        <BlurredBox
          status="danger"
          style={{
            width: "100%",
          }}
        >
          <VStack p="2">
            <Heading size="lg">Unable to load data</Heading>
            <Text>Please check your network connection.</Text>
          </VStack>
        </BlurredBox>
      </Box>
    );
  return null;
}
