import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button, Heading, Icon, useColorMode, useToken, VStack } from "native-base";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { PanelFlatList } from "~/components/BottomSheet/BottomSheetCompatibleViews";
import { ApiV2Asset, useLocate } from "../api/locate";
import { useLocateOptions } from "~/common/hooks/useLocateOptions";
import { useSearch } from "~/common/hooks/useSearch";
import Fuse from "fuse.js";
import dayjs from "dayjs";
import { PanelHeader } from "~/components/BottomSheet/PanelHeader";
import { ANIMATION_SOURCE, useBottomSheetInternal } from "@gorhom/bottom-sheet";
import { BottomSheetInput } from "~/components/BottomSheet/BottomSheetInput";
import { AssetIcon } from "../display/assetIcons";
import { CacheFixedImage } from "~/components/Images/CacheFixedImage";
import { ApiV2AssetStatus } from "~/features/locate/api/common";
import { useAtomValue } from "jotai";
import { disableAssetListAtom } from "~/features/settings/debugging/screen";
import { View, Pressable, Text } from "react-native";
import Color from "color";

export function AssetEntry({ asset }: { asset: ApiV2Asset }) {
  const { group, selectedAsset } = useLocateOptions();
  const selectedAssetRef = useRef<number | undefined>(undefined);
  useEffect(() => {
    selectedAssetRef.current = selectedAsset;
  }, [selectedAsset]);
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const textColor = useToken("colors", colorMode === "dark" ? "dark.900" : "light.900");
  const selectBgColor = useToken("colors", colorMode === "dark" ? "primary.100" : "primary.500");
  return useMemo(
    () => (
      <Pressable
        key={asset.id}
        onPress={() =>
          navigate(`/locate/${group}/asset/${asset.id}`, { replace: !!selectedAssetRef.current })
        }
      >
        <View
          style={{
            height: 60,
            flexDirection: "row",
            padding: 8,
            alignItems: "center",
            backgroundColor:
              asset.id === selectedAsset
                ? Color(selectBgColor)
                    .alpha(colorMode === "dark" ? 0.1 : 0.2)
                    .hexa()
                : undefined,
          }}
        >
          <AssetIcon size={6} asset={asset} />
          <View style={{ width: 8 }} />
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: "bold",
                color: textColor,
              }}
            >
              {asset.name}
              {asset.driver ? (
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    opacity: 0.8,
                    color: textColor,
                  }}
                >
                  {" "}
                  - {asset.driver}
                </Text>
              ) : null}
            </Text>
            {!!(asset.address || asset.zone) && (
              <Text
                style={{
                  color: textColor,
                }}
                numberOfLines={1}
              >
                {asset.zone || asset.address}
              </Text>
            )}
          </View>
        </View>
      </Pressable>
    ),
    [asset, selectedAsset === asset.id, textColor, selectBgColor]
  );
}

function renderAssetEntry({ item }: { item: ApiV2Asset }) {
  return <AssetEntry asset={item} />;
}

export function ListPanel() {
  const { query, assets } = useLocate();
  const { group } = useLocateOptions();
  const navigate = useNavigate();

  const { colorMode } = useColorMode();

  const [value, search, onValue] = useSearch(300);

  const filteredAssets = useMemo(() => {
    if (!search) {
      return assets;
    }
    const fuse = new Fuse(assets, {
      keys: ["name"],
      threshold: 0.3,
    });
    return fuse.search(search).map((i) => i.item);
  }, [assets, search]);

  const [seeUninstalled, setSeeUninstalled] = useState(false);

  const bottomSheet = useBottomSheetInternal(true);

  const disableAssetList = useAtomValue(disableAssetListAtom);

  return (
    <PanelFlatList
      header={
        <PanelHeader
          title={query.data?.group?.name ?? (query.data ? "All Assets" : "Loading...")}
          titleRight={` (${
            assets.filter(
              (i) =>
                i.status !== ApiV2AssetStatus.NotInstalled &&
                i.status !== ApiV2AssetStatus.Uninstalled
            ).length
          })`}
          subtitle={`Updated ${
            query.dataUpdatedAt ? `at ${dayjs(query.dataUpdatedAt).format("HH:mm")}` : "Never"
          }`}
          button={
            group !== "all" ? (
              <Button
                size="md"
                colorScheme="secondary"
                onPress={() => navigate(`/locate/all`, { replace: true })}
                startIcon={<Icon as={MaterialCommunityIcons} name="filter-remove" size="md" />}
              >
                Show All
              </Button>
            ) : (
              <Button
                size="md"
                onPress={() => {
                  navigate(`/locate/${group}/groups`);
                  bottomSheet?.animateToPosition(
                    bottomSheet?.animatedSnapPoints.value[1],
                    ANIMATION_SOURCE.USER
                  );
                }}
                startIcon={<Icon as={MaterialCommunityIcons} name="filter" size="md" />}
              >
                Group
              </Button>
            )
          }
        />
      }
      style={{ flex: 1 }}
      ListHeaderComponent={
        <VStack>
          <BottomSheetInput
            value={value}
            onChangeText={(val) => onValue(val)}
            size="md"
            m="2"
            InputLeftElement={
              <Icon as={<MaterialCommunityIcons name="magnify" />} size="md" ml="2" />
            }
            placeholder="Search"
          />
        </VStack>
      }
      keyExtractor={(item) => item.id.toString()}
      data={
        disableAssetList
          ? []
          : filteredAssets.filter(
              (i) =>
                i.status !== ApiV2AssetStatus.NotInstalled &&
                i.status !== ApiV2AssetStatus.Uninstalled
            )
      }
      getItemLayout={(data, index) => ({ length: 60, offset: 60 * index, index })}
      renderItem={renderAssetEntry}
      ListFooterComponent={
        <VStack>
          <Button
            startIcon={
              <Icon
                as={MaterialCommunityIcons}
                name={seeUninstalled ? "eye-off" : "eye"}
                size="md"
              />
            }
            variant="ghost"
            colorScheme="gray"
            textAlign="left"
            onPress={() => setSeeUninstalled(!seeUninstalled)}
          >
            {`${seeUninstalled ? "Hide" : "Show"} Uninstalled Assets`}
          </Button>
          {seeUninstalled && (
            <>
              <Heading p="2" fontSize="lg">
                Uninstalled Assets (
                {
                  filteredAssets.filter(
                    (i) =>
                      i.status === ApiV2AssetStatus.NotInstalled ||
                      i.status === ApiV2AssetStatus.Uninstalled
                  ).length
                }
                )
              </Heading>
              {assets
                .filter(
                  (i) =>
                    i.status === ApiV2AssetStatus.NotInstalled ||
                    i.status === ApiV2AssetStatus.Uninstalled
                )
                .map((asset) => (
                  <Pressable style={{ padding: 8 }} key={`uninstalled-${asset.id}`}>
                    <Heading fontSize="md">{asset.name}</Heading>
                  </Pressable>
                ))}
            </>
          )}
          <CacheFixedImage
            alt="Powered by Trackitnow"
            source={
              colorMode === "dark"
                ? require("~/assets/misc/logo_dark.png")
                : require("~/assets/misc/logo_light.png")
            }
            width="full"
            height="20"
            resizeMode="contain"
          />
        </VStack>
      }
    />
  );
}
